import React, { useEffect, useState } from 'react';
import {
	Avatar,
	Title,
	Text,
	Stack,
	Group,
	useMantineTheme,
	Indicator,
	Loader,
	CopyButton,
	ActionIcon,
} from '@mantine/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSlack } from '@fortawesome/free-brands-svg-icons';
import { generateSlackURL } from '../../helpers/data';
import { Link } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
	currentHost,
	isProjectManagerAtom,
	user as userAtom,
} from '../../Atoms/userAtoms';
import { IMeeting } from '../../interfaces/meeting';
import { DateTime } from 'luxon';
import { MeetingInfoBar } from '../MeetingInfoBar';
import { swapBackgroundColor } from '../../_utils/handy-functions';
import { faUpFromBracket, faCheck } from '@fortawesome/pro-solid-svg-icons';
import { notifications } from '../../Atoms/notifications';
import {
	createMeetingShareableLink,
	listMeetingShareableLinks,
	updateSharingTokenIsActive,
} from '../../api/api';
import { logger } from '../../helpers/logger';
import { meetingShareableLinks } from '../../Atoms/meetingAtom';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../helpers/notifications';
import { ShareableLink } from '../../helpers/links';
import { isAuthenticated } from '../../Atoms/auth';
import { organizationSettings } from '../../Atoms/settings';
import { modals } from '@mantine/modals';

interface Props {
	meeting: IMeeting;
	setMeeting: React.Dispatch<React.SetStateAction<any>>;
}

export default function MobileOverview({ meeting, setMeeting }: Props) {
	const theme = useMantineTheme();
	const { sharing: sharingEnabled } = useRecoilValue(organizationSettings);
	const setCurrentHost = useSetRecoilState(currentHost);
	const userNotifications = useRecoilValue(notifications);
	const user = useRecoilValue(userAtom);
	const setShareableLinks = useSetRecoilState(meetingShareableLinks);

	const requestUsers = userNotifications.filter(
		(notification) => notification.meetingID === meeting.id
	);
	const isPM = useRecoilValue(isProjectManagerAtom);
	const isMeetingHostOrPM = meeting.owningUserID === user.id || isPM;

	const [sharing, setSharing] = useState<boolean>(false);
	const [url, setUrl] = useState<string>('');
	const shareable = 'share' in navigator;
	const isAuthorized = useRecoilValue(isAuthenticated);

	useEffect(() => {
		const getMeetingLinks = async () => {
			try {
				const res = await listMeetingShareableLinks(
					meeting.id,
					meeting.organizationID
				);
				const shareableLinks = res?.data?.data;

				let mobileLink = shareableLinks.find(
					(link: ShareableLink) =>
						link.title === 'Mobile' && link.meetingID === meeting.id
				);

				if (!mobileLink) {
					const newMobileLink = await createMeetingShareableLink(
						meeting.id,
						meeting.organizationID,
						'Mobile'
					);
					mobileLink = newMobileLink?.data?.data;
					setShareableLinks([...shareableLinks, mobileLink]);
				} else {
					setShareableLinks(shareableLinks);
				}

				const { origin } = window.location;
				let copyLinkValue = `${origin}/meetings/${meeting.id}`;
				if (meeting?.sharingToken?.active) {
					copyLinkValue += `?vt=${meeting.sharingToken.token}`;
				}
				setUrl(copyLinkValue);
			} catch (error) {
				logger('error', 'failed to get meeting links', error);
			}
		};
		if (isAuthorized) {
			getMeetingLinks();
		}
	}, []);

	/**
	 * set the current host being viewed.
	 */
	const handleHostClick = () => {
		setCurrentHost(meeting?.author);
	};

	const handleSlackClick = () => {
		const slackURL = generateSlackURL(meeting.channelID, meeting.teamID);
		window.location.href = slackURL;
	};

	const shareLink = async () => {
		try {
			setSharing(true);
			const payload = {
				title: 'Meeting Link',
				text: `${user.firstName} ${user.lastName} wants to share a Reelay with you! Click the link to gain access.`,
				url,
			};

			navigator
				?.share(payload)
				.then((res) => {
					logger('info', 'link shared', res);
					showSuccessNotification({
						message: 'Link successfully shared. Great job!',
					});
				})
				.catch((error) => {
					logger('error', 'failed to share link', error);
				});
		} catch (error) {
			console.log('error', error);
			logger('error', 'failed to share link', error);
			showFailureNotification({
				message:
					'Sorry, link sharing failed. Please try again or contact support.',
			});
		} finally {
			setSharing(false);
		}
	};

	const makeMeetingShareable = async (meeting) => {
		const {
			data: { data: sharingToken },
		} = await updateSharingTokenIsActive(
			{
				targetID: meeting.id,
				value: true,
			},
			meeting.organizationID
		);
		return sharingToken;
	};

	const copySharingLink = async () => {
		const { origin } = window.location;
		let copyLinkValue = `${origin}/meetings/${meeting.id}`;
		const sharingToken = meeting?.sharingToken;
		if (sharingToken?.active) {
			copyLinkValue += `?vt=${sharingToken.token}`;
		}
		await navigator.clipboard.writeText(copyLinkValue);
		showSuccessNotification({
			message: `New link was successfully copied.`,
		});
	};

	const handleShareSheetButtonClick = async () => {
		if (!meeting.sharingToken?.active && isMeetingHostOrPM) {
			await suggestMakeMeetingShareable();
		}
		await copySharingLink();
		return shareLink();
	};

	const suggestMakeMeetingShareable = async () =>
		new Promise<string | void>((resolve, reject) => {
			modals.openConfirmModal({
				title: 'Before you share',
				children: (
					<Stack>
						<Text size='sm'>
							This meeting is currently set to "Invite Only". Sending a link in
							this way will cause the reciepient to need to Request Access. If
							you would like for them to have a simpler experience that does not
							require for them to have an account, we can change this meeting to
							be accessible by anyone with the link.
						</Text>
						<Text size='sm'>Would you like to change it now?</Text>
					</Stack>
				),
				labels: { confirm: 'Yes', cancel: 'No' },
				onConfirm: async () => {
					try {
						const sharingToken = await makeMeetingShareable(meeting);
						setMeeting((prev) => ({
							...prev,
							sharingToken,
						}));

						showSuccessNotification({
							message: `Meeting was made shareable.`,
						});
						resolve(
							`${origin}/meetings/${meeting.id}?vt=${meeting.sharingToken.token}`
						);
					} catch (err) {
						console.log(err);
						logger('error', 'Error making meeting shareable', err);
						showFailureNotification({
							message: `Apologies, making meeting shareable failed. Please try the operation again.`,
						});
						reject(err);
					}
				},
				onCancel: resolve,
			});
		});

	const ShareLinkCopyButton = () => {
		return (
			<CopyButton value={url} timeout={2000}>
				{({ copied, copy }) => (
					<ActionIcon
						variant='transparent'
						onClick={async () => {
							if (!meeting.sharingToken?.active && isMeetingHostOrPM) {
								const sharingLink = await suggestMakeMeetingShareable();
								if (sharingLink) {
									await navigator.clipboard.writeText(sharingLink);
									setUrl(sharingLink);
								} else {
									copy();
								}
							} else {
								copy();
							}
							showSuccessNotification({
								message: 'Link copied to clipboard.',
							});
						}}
					>
						{copied ? (
							<FontAwesomeIcon icon={faCheck} size={'lg'} />
						) : (
							<FontAwesomeIcon icon={faUpFromBracket} size={'lg'} />
						)}
					</ActionIcon>
				)}
			</CopyButton>
		);
	};

	return (
		<Stack m={'lg'}>
			<Stack spacing={0}>
				<Title size={21}>{meeting?.name}</Title>
				<Text size={14} color={'secondary-text'}>
					{(typeof meeting?.publishedAt === 'number'
						? DateTime.fromMillis(meeting?.publishedAt)
						: DateTime.fromISO(meeting?.createdAt)
					).toFormat('MM/dd/yyyy')}
				</Text>
			</Stack>
			<Group noWrap position={'apart'}>
				<Group position={'left'} spacing={'xs'}>
					<Avatar
						onClick={handleHostClick}
						component={Link}
						src={swapBackgroundColor(
							`${meeting?.author?.userAvatarURL}-196.png`,
							theme.fn.primaryColor()
						)}
						alt='host image'
						radius='xl'
						size={'md'}
						to={`/users/${meeting?.author?.id}`}
					>
						{`${meeting?.author?.firstName?.substring(
							0,
							1
						)}${meeting?.author?.lastName?.substring(0, 1)}`}
					</Avatar>
					<Text size={16} fw={400}>
						{meeting?.author?.firstName} {meeting.author.lastName}
					</Text>
				</Group>
				<Group position={'right'} spacing={'md'} noWrap mr={'sm'}>
					{/*add ability to open chat in slack*/}
					{meeting?.channelID && meeting?.teamID && (
						<FontAwesomeIcon
							onClick={handleSlackClick}
							icon={faSlack}
							size={'lg'}
						/>
					)}
					{requestUsers.length > 0 && isMeetingHostOrPM ? (
						<Indicator
							styles={{ indicator: { padding: 2 } }}
							color='red'
							size={15}
							label={requestUsers.length}
							position={'bottom-end'}
						>
							{sharingEnabled ? (
								<>
									{shareable ? (
										sharing ? (
											<Loader size={'sm'} />
										) : (
											<FontAwesomeIcon
												icon={faUpFromBracket}
												onClick={handleShareSheetButtonClick}
												size={'lg'}
											/>
										)
									) : (
										<ShareLinkCopyButton />
									)}
								</>
							) : null}
						</Indicator>
					) : shareable ? (
						<>
							{sharing ? (
								<Loader size={'sm'} />
							) : (
								<FontAwesomeIcon
									icon={faUpFromBracket}
									onClick={handleShareSheetButtonClick}
									size={'lg'}
								/>
							)}
						</>
					) : (
						<ShareLinkCopyButton />
					)}
				</Group>
			</Group>

			<MeetingInfoBar
				meeting={meeting}
				backgroundColor={theme.colors['background-gray'][0]}
			/>
		</Stack>
	);
}
