import { Modal, Text, ScrollArea, Stack, Group, Button } from '@mantine/core';
import ModalTitle from '../../Titles/ModalTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFolderTree,
	faPaperclipVertical,
} from '@fortawesome/pro-light-svg-icons';
import { IMeeting } from '../../../interfaces/meeting';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ShareLinkRow } from '../../Popovers/ShareMeetingPopover/components/ShareLinkRow';
import { ShareableLink, sortLinksByStatus } from '../../../helpers/links';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../helpers/notifications';
import {
	createMeetingShareableLink,
	deleteMeetingShareableLink,
	listMeetingShareableLinks,
} from '../../../api/api';
import { logger } from '../../../helpers/logger';
import { compareByTimestamp } from '../../../helpers/timestamp';
import { useEffect, useState } from 'react';
import { currentUser, isProjectManagerAtom } from '../../../Atoms/userAtoms';
import {
	currentMeeting,
	currentMeetingDistributionList,
	meetingShareableLinks,
} from '../../../Atoms/meetingAtom';
import { ShareableLinkTable } from '../../tables/ShareableLinkTable';

interface Props {
	opened: boolean;
	close: () => void;
}

export default function MyLinksModal({ close, opened }: Props) {
	const user = useRecoilValue(currentUser);
	const meeting = useRecoilValue(currentMeeting);
	const [linkList, setLinkList] = useRecoilState(meetingShareableLinks);
	// const [showLinkRow, setShowLinkRow] = useRecoilState(showLinkRowAtom);
	const viewers = useRecoilValue(currentMeetingDistributionList);
	const [showAdvanced, setShowAdvanced] = useState(false);
	const isPM = useRecoilValue(isProjectManagerAtom);
	const isMeetingHostOrPM = meeting.owningUserID === user.id || isPM;
	const userLink = viewers.find((viewer) => viewer.id === user.id);
	// if you are a member (meeting host or not) you should see all your links.
	// if you are a guest you should see link you were invited to.
	const viewableLinks = user.roles.includes('guest')
		? linkList.filter((item) => item.id === userLink?.userlink?.shareableLinkID)
		: linkList.filter((item) => item.userID === user.id);

	useEffect(() => {
		const fetchMeetingShareableLinks = async () => {
			const links = await listMeetingShareableLinks(
				meeting.id,
				meeting.organizationID
			);
			setLinkList(
				links.data.data
					.slice()
					.sort((a, b) => compareByTimestamp(a, b, 'createdAt', 'asc'))
			);
		};
		try {
			if (meeting.id && meeting.organizationID) {
				fetchMeetingShareableLinks();
				logger('info', 'fetchMeetingShareableLinks successful', {
					meetingID: meeting.id,
					organizationID: meeting.organizationID,
				});
			}
		} catch (error) {
			logger('error', 'failed to fetch meeting shareable links', error);
			showFailureNotification(error);
		}
	}, [meeting]);

	/**
	 * Generates a shareable link for this meeting.
	 */
	const generateShareableLink = async () => {
		try {
			// create new link
			const newLink = await createMeetingShareableLink(
				meeting.id,
				meeting.organizationID
			);
			// add to the link list.
			setLinkList([...linkList, newLink.data.data]);
			showSuccessNotification({
				message: 'Link was successfully generated.  Great work!',
			});
			logger('info', 'link generated', newLink.data.data);
		} catch (error) {
			logger('error', 'failed to generate link', error);
			showFailureNotification({
				message: 'Sorry, failed to generate link. Please try again.',
			});
		}
	};

	const deleteShareableLink = async (link: ShareableLink) => {
		const { id, meetingID, organizationID } = link;
		try {
			// delete shareable link
			const deletedLink = await deleteMeetingShareableLink(
				id,
				meetingID,
				organizationID
			);
			const deletedLinkList = deletedLink?.data?.data?.linkIDs;
			// update links list in state
			setLinkList((list) =>
				list.filter((link) => !deletedLinkList.includes(link.id))
			);
			// setOpened(false);
			showSuccessNotification({
				message: 'Link was successfully deleted. Great work!',
			});
		} catch (error) {
			console.log('error', error);
			const message =
				error?.response?.data === 'Active shareable link can not be removed'
					? 'Active shareable link can not be removed. Please turn off link and try again.'
					: error?.response?.data;
			showFailureNotification({
				message,
			});
		}
	};

	return (
		<Modal
			opened={opened}
			onClose={() => {
				close();
				setShowAdvanced(false);
			}}
			title={
				<ModalTitle
					text={showAdvanced ? 'All Links' : 'My Links'}
					icon={<FontAwesomeIcon icon={faFolderTree} />}
				/>
			}
			size={showAdvanced ? '90%' : 'xl'}
			zIndex={10000}
			radius={'lg'}
			withinPortal={false}
			styles={(theme) => ({
				header: {
					borderRadius: theme.spacing.lg,
				},
			})}
			maw={1400}
		>
			{showAdvanced ? (
				<ShareableLinkTable data={linkList} setShowAdvanced={setShowAdvanced} />
			) : (
				<Stack>
					<Group position={'apart'}>
						<Button
							leftIcon={<FontAwesomeIcon icon={faPaperclipVertical} />}
							variant='subtle'
							radius='md'
							compact
							onClick={generateShareableLink}
						>
							Generate Link
						</Button>
						{user.id === meeting.owningUserID && (
							<Button
								styles={{
									label: { textDecoration: 'underline' },
								}}
								variant='subtle'
								radius='md'
								compact
								onClick={() => setShowAdvanced(!showAdvanced)}
							>
								Advanced
							</Button>
						)}
					</Group>
					{/* {showLinkRow ? (
                        <Stack pr={10}>
                            <ShareLinkRow
                                link={linkList[linkList.length - 1]}
                                saveButton={true}
                                deleteShareableLink={deleteShareableLink}
                            />
                        </Stack>
                    ) : null} */}
					<ScrollArea
						h={800}
						offsetScrollbars
						scrollbarSize={10}
						styles={(theme) => ({
							scrollbar: {
								'&[data-orientation="vertical"] .mantine-ScrollArea-thumb': {
									backgroundColor: theme.colors['primary'][0],
								},
							},
						})}
					>
						{viewableLinks.length ? (
							<Stack h={'100%'}>
								{sortLinksByStatus(viewableLinks).map((link) => (
									<ShareLinkRow
										key={link.id}
										link={link}
										deleteShareableLink={deleteShareableLink}
										isMeetingHostOrPM={isMeetingHostOrPM}
									/>
								))}
							</Stack>
						) : (
							<Text color={'primary-text'}>No shareable links made yet.</Text>
						)}
					</ScrollArea>
				</Stack>
			)}
		</Modal>
	);
}
