import React from 'react';
import Lottie from 'lottie-react';
import * as animationData from './reelay-loading-icon-middle-v2.json';
import { Center, createStyles, Loader } from '@mantine/core';
import { useRecoilValue } from 'recoil';
import { organizationBrand } from '../../Atoms/settings';

export const ReelayLoader = ({
	height = 100,
	width = 100,
}: {
	height?: string | number;
	width?: string | number;
}) => {
	const brand = useRecoilValue(organizationBrand);
	if (brand?.logo_url) return <Loader size={100} />;

	return (
		<Lottie
			loop={true}
			animationData={animationData}
			style={{
				width,
				height,
				marginLeft: 'auto',
				marginRight: 'auto',
			}}
		/>
	);
};
