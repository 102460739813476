import { Stack, Title, ScrollArea } from '@mantine/core';
import UpcomingMeetingItem from './UpcomingMeetingItem';
import { useRecoilState } from 'recoil';
import { upcomingMeetings } from '../../Atoms/recallAI';
import { BotStatus } from '../../interfaces/recallai';

export default function UpcomingMeetingStack() {
	const [meetings, setMeetings] = useRecoilState(upcomingMeetings);
	const upcomingMeetingsWithBots = meetings.filter(
		(meeting) => meeting?.botMetadata?.status !== BotStatus.BotDeleted
	);

	return (
		<Stack
			justify='flex-end'
			spacing={'sm'}
			style={{
				// flex: 1,
				overflowY: 'hidden',
			}}
		>
			{upcomingMeetingsWithBots.length ? (
				<Title order={6} weight={500}>
					Upcoming
				</Title>
			) : null}
			<ScrollArea
				offsetScrollbars
				scrollbarSize={10}
				style={{ flex: 1, overflowY: 'auto' }}
			>
				<Stack spacing={'sm'} justify='flex-end' style={{ flex: 1 }}>
					{upcomingMeetingsWithBots.map((meeting) => (
						<UpcomingMeetingItem key={meeting.id} meeting={meeting} />
					))}
				</Stack>
			</ScrollArea>
		</Stack>
	);
}
