import React from 'react';
import { createStyles, Grid, Group, Stack, Title } from '@mantine/core';
import { UserCard } from './components/UserCard';
import { PersonalInformationCard } from './components/PersonalInformationCard';
import AddressCard from './components/AddressCard';
import MeetingMinutesCard from './components/MeetingMinutesCard';
import SharingCard from './components/SharingCard';
import {useRecoilValue} from "recoil";
import {organizationSettings} from "../../../../Atoms/settings";

const useStyles = createStyles(() => ({
	outerContainer: {
		overflowY: 'auto',
	},
}));

export const MyProfile = () => {
	const { classes } = useStyles();
	const {sharing} = useRecoilValue(organizationSettings);

	return (
		<div>
			<Grid>
				<Grid.Col span={3}>
					<Stack p={'md'} h={'100%'} className={classes.outerContainer}>
						<UserCard />
					</Stack>
				</Grid.Col>
				<Grid.Col span={9}>
					<Stack p={'md'} h={'100%'} className={classes.outerContainer}>
						<PersonalInformationCard />
						<AddressCard />
						<MeetingMinutesCard />
						{!sharing ? null : <SharingCard />}
					</Stack>
				</Grid.Col>
			</Grid>
		</div>
	);
};

export default MyProfile;
