import { Paper, Switch, Text } from '@mantine/core';
import { styles } from '../../../../../../settings/tabs/styles';
import { OrganizationSettings } from '../../../../../../../Atoms/settings';
import { useState } from 'react';
import { useParams } from 'react-router';

interface Props {
	organizationSettings: OrganizationSettings;
	setOrganizationSettings: React.Dispatch<
		React.SetStateAction<OrganizationSettings>
	>;
	handleSettingChange: (setting: string, value: any) => void;
}

export default function SkipAudit({
	organizationSettings,
	setOrganizationSettings,
	handleSettingChange,
}: Props) {
	const { classes } = styles();
	const { organizationID } = useParams();
	const { skip_audit: skipAudit } = organizationSettings;
	const [checked, setChecked] = useState<boolean>(skipAudit);
	if (checked !== skipAudit) {
		setChecked(skipAudit);
	}

	const handleChange = (value: boolean) => {
		try {
			setChecked(value);
			handleSettingChange('skip_audit', value);
		} catch (error) {
			console.error(error);
			setChecked(!value);
		}
	};

	return (
		<Paper className={classes.card} shadow='xs' p='md' radius={'md'}>
			<Switch
				labelPosition='left'
				description={
					<Text
						style={{
							cursor: 'default',
						}}
					>
						When this is enabled the meeting will undergo processing and checks
						for content. Then instead of moving to audit it will move directly
						to published. If any checks fail (No transcript, no video, no
						summary, no moments, no topics), the meeting will be moved to audit
						instead.
					</Text>
				}
				size='sm'
				w={'100%'}
				width={'100%'}
				mb={'lg'}
				checked={checked}
				onChange={(e) => handleChange(e.currentTarget.checked)}
				styles={{
					body: {
						width: '100%',
						justifyContent: 'space-between',
						flex: 1,
					},
				}}
			/>
		</Paper>
	);
}
