import { logger } from '../helpers/logger';
import axios from 'axios';
import _ from 'lodash';

const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_BASE_AI_SERVICE_URL, // Set your base URL
	timeout: 180000, // Set the timeout for requests
	// You can add more default configurations as needed
});

// http://localhost:3002/meetings/process/MET_cmkmk0c6cgwp85rryha0/highlights
export const getMeetingDetailsFromAI = (
	meetingID: string,
	type: string,
	signal,
	model,
	headers = {}
) => {
	return axiosInstance
		.get(`meetings/process/${meetingID}/${model}/${type}`, {
			headers,
			signal,
		})
		.then((res) => {
			logger('info', 'Response received from getOrganizationSettings', res);
			return _.get(res, 'data.data', []);
		})
		.catch((err) => {
			if (axios.isCancel(err)) {
				return err;
			} else {
				logger('error', 'Error getting meeting details', err);
				throw err;
			}
		});
};
