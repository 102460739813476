import { DateTime } from 'luxon';
import { MILLISECONDS_PER_SECOND } from '../components/constants';
import { formatSecondsToMinutesAndSeconds } from '../_utils/time';
import { User } from '../Atoms/userAtoms';

// Create an interface for the Teams Response
export interface Team {
	createdAt: string;
	id: string;
	name: string;
	organizationID: string;
	owningUserID: string;
	updatedAt: string;
	users: User[];
}
export interface Utterance {
	start: number;
	speaker: string;
	text: string;
}

export const buildAudioTranscript = (utterances: Utterance[]) => {
	let richTextTranscript = '';
	// create rich text <p> for each utterance
	for (const utterance of utterances)
		richTextTranscript += `<p><b>(${formatSecondsToMinutesAndSeconds(
			utterance.start / MILLISECONDS_PER_SECOND
		)}) - ${utterance.speaker}:</b> ${utterance.text}</p>`;

	return richTextTranscript;
};

export const reelayVideoThumbnailFallbackImage =
	'https://reelay-ai-staging.s3.us-west-1.amazonaws.com/assets/images/thumbnail-fallback-1920x1080.png';

export const createVideoThumbnail = (
	playbackID: string | null | undefined,
	thumbnailTime: number | null
) => {
	// when playbackID is null (occurs when you upload non video for meeting), use
	// the reelay thumbnail fallback img.
	if (!playbackID || thumbnailTime === 0)
		return reelayVideoThumbnailFallbackImage;
	return (
		'https://image.mux.com/' +
		playbackID +
		`/thumbnail.png?width=800&height=400&time=${
			thumbnailTime !== null ? thumbnailTime : 35
		}`
	);
};

export const formatDeadlineTime = (isoString: string | undefined) => {
	if (!isoString) return 'N/A';
	return DateTime.fromISO(isoString).toLocaleString(DateTime.DATE_SHORT);
};

export const getCurrentYear = () => {
	const currentDate = new Date();
	return currentDate.getFullYear();
};

export const generateSlackURL = (channelID: string, teamID: string) => {
	if (channelID && teamID)
		return `slack://channel?team=${teamID}&id=${channelID}`;

	return 'slack://open';
};
