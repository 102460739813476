import React from 'react';
import { Button, Text, Stack, Image } from '@mantine/core';
import { IMeeting } from '../../../../interfaces/meeting';
import { createVideoThumbnail } from '../../../../helpers/data';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
	currentMeeting,
	myMeetings,
	videoPlayerCurrentTime,
} from '../../../../Atoms/meetingAtom';
import { updateMeeting } from '../../../../api/api';
import { showNotification } from '@mantine/notifications';
import {
	defaultFailureNotificationProps,
	defaultSuccessNotificationProps,
} from '../../../../components/constants';
import { currentUserReelays } from '../../../../Atoms/userAtoms';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../../helpers/notifications';
import { logger } from '../../../../helpers/logger';

interface Props {
	meeting: IMeeting;
}
export default function ThumbnailSetter({ meeting }: Props) {
	const { videoMetadata, thumbnailTime } = meeting;
	const currentTime = useRecoilValue(videoPlayerCurrentTime);
	const setMyMeetings = useSetRecoilState(myMeetings);
	const setCurrentUserReelays = useSetRecoilState(currentUserReelays);
	const setCurrentMeeting = useSetRecoilState(currentMeeting);

	const saveThumbnail = async () => {
		try {
			// save video thumbnail in seconds
			const response = await updateMeeting(
				{ thumbnailTime: currentTime },
				meeting.id,
				meeting.organizationID
			);
			const updatedMeeting = response?.data?.data;
			setMyMeetings((oldMeetings) =>
				oldMeetings.map((oldMeeting) =>
					oldMeeting.id === updatedMeeting.id ? updatedMeeting : oldMeeting
				)
			);
			setCurrentUserReelays((oldMeetings: IMeeting[]) =>
				oldMeetings.map((oldMeeting: IMeeting) =>
					oldMeeting.id === updatedMeeting.id ? updatedMeeting : oldMeeting
				)
			);
			setCurrentMeeting(updatedMeeting);
			showSuccessNotification({
				message: 'Meeting was successfully updated! Great work!',
			});
		} catch (error) {
			logger('error', 'error updating meeting', error);
			showFailureNotification({
				message: 'There was an error updating the meeting. Please try again.',
			});
		}
	};

	return (
		<Stack align={'flex-start'}>
			<Text size={'sm'} color={'primary-text'}>
				You can change the thumbnail of this video by using the video player
				above. Move the video time to the frame you want then click save.
			</Text>
			<Button onClick={saveThumbnail}>Save Thumbnail</Button>
			<Image
				caption={'Current Thumbnail'}
				src={createVideoThumbnail(videoMetadata.playbackID, thumbnailTime)}
			/>
		</Stack>
	);
}
