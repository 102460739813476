/* eslint-disable no-unreachable */
import React, { useEffect, useState } from 'react';
import {
	Modal,
	Button,
	TextInput,
	Group,
	Stack,
	Text,
	List,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useForm } from '@mantine/form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { useRecoilState } from 'recoil';
import {
	DeletingUser,
	User,
	userOrganizationMembers,
} from '../../../Atoms/userAtoms';
import {
	defaultFailureNotificationProps,
	defaultSuccessNotificationProps,
	roles,
} from '../../constants';
import { UsersMultiSelect } from '../../UsersMultiSelect';
import { logger } from '../../../helpers/logger';
import {
	inviteUserToOrganization,
	removeUser,
	updateOrganizationUser,
} from '../../../api/api';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../helpers/notifications';
import { Organization } from '../../../Atoms/organizationAtom';

interface Props {
	member: DeletingUser;
	opened: boolean;
	close: () => void;
}
export default function DeleteUserModal({ member, opened, close }: Props) {
	const [users, setUsers] = useRecoilState(userOrganizationMembers);
	const [loading, setLoading] = useState(false);

	const form = useForm({
		initialValues: {
			email: '',
		},
		validate: {
			email: (value) =>
				value !== member.email ? 'Email does not match' : null,
		},
	});

	async function deleteUser(data) {
		try {
			setLoading(true);
			// need to delete user from everything once endpoint is made.
			const deletedUser = await removeUser(member.id);
			if (deletedUser?.data?.data?.success) {
				// update users list
				setUsers(users.filter((user: User) => user.id !== member.id));
				showSuccessNotification({
					message: 'User was successfully deleted. Great work!',
				});
			} else {
				logger('error', 'Error deleting user - ', deletedUser);
				showFailureNotification({
					message:
						'Sorry, there was an error when deleting a user. Please try again.',
				});
			}
		} catch (err: unknown) {
			logger('error', 'Error deleting user - ', err);
			showFailureNotification({
				message:
					'Sorry, there was an error when deleting a user. Please try again.',
			});
		} finally {
			setLoading(false);
			form.reset();
			close();
		}
	}

	return (
		<Modal
			opened={opened}
			centered={true}
			onClose={close}
			title='Are you sure?'
		>
			<form onSubmit={form.onSubmit((values) => deleteUser(values))}>
				<Stack>
					<Text size='sm'>
						By deleting this user you will be removing them from the following
						organizations:
					</Text>
					<List size={'sm'}>
						{member.organizations.map((org: Organization) => (
							<List.Item key={org.id}>{org.name}</List.Item>
						))}
					</List>
					<Text size={'sm'}>
						If you are sure you would like to perform this action then type the
						user's email below:
					</Text>
					<Text size={'sm'} fs={'italic'} data-autofocus>
						{member.email}
					</Text>
					<TextInput
						placeholder={'Enter email here'}
						{...form.getInputProps('email')}
					/>
					<Group position='right'>
						<Button variant={'default'} onClick={close}>
							Keep User
						</Button>
						<Button type={'submit'} loading={loading}>
							Confirm
						</Button>
					</Group>
				</Stack>
			</form>
		</Modal>
	);
}
