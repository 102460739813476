import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import {
	Table,
	Paper,
	Group,
	List,
	ActionIcon,
	Text,
	Button,
	Badge,
	Menu,
	TextInput,
	Stack,
	ScrollArea,
	Title,
} from '@mantine/core';
import { useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import {
	searchOrganizationsByUserID,
	getUserByIDWithoutOrg,
	getUsersByOrg,
	removeUserFromOrganization,
} from '../../api/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faTrash,
	faUserPen,
	faUserSlash,
	faEllipsisVertical,
} from '@fortawesome/pro-solid-svg-icons';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
	currentUser,
	DeletingUser,
	User,
	userOrganizationMembers,
} from '../../Atoms/userAtoms';
import { logger } from '../../helpers/logger';
import EditUserModal from './EditUserModal';
import { Organization, organizations } from '../../Atoms/organizationAtom';
import ReelayLogoPageLoader from '../loaders/ReelayLogoPageLoader';
import { modals } from '@mantine/modals';
import { organizationAtom as organizationAtom } from '../../Atoms/organizationAtom';
import { showNotification } from '@mantine/notifications';
import {
	defaultFailureNotificationProps,
	defaultSuccessNotificationProps,
} from '../constants';
import { useForm } from '@mantine/form';
import { useDebouncedValue, useDisclosure } from '@mantine/hooks';
import DeleteUserModal from './DeleteUserModal';
import { UserRoles } from '../../interfaces/user';
import SearchBar from '../SearchBars/SearchBar';
import CreateQuickAddUserModal from '../create_quickadd_user_modal';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../helpers/notifications';
import { getEnvForGleap } from '../../_utils/trackers';

const userDefaultValue = {
	firstName: '',
	lastName: '',
	email: '',
	roles: [],
};

export default function OrganizationMembersTable({
	organizationID,
	tabValue = '',
	auditSide = false,
}: {
	organizationID: string;
	tabValue?: string;
	auditSide?: boolean;
}) {
	const [members, setMembers] = useRecoilState(userOrganizationMembers);
	const [filteredMembers, setFilteredMembers] = useState(members);
	const [loading, setLoading] = useState(false);
	const [organization, setOrganization] =
		useRecoilState<Organization>(organizationAtom);
	const user = useRecoilValue(currentUser);
	const [opened, setOpened] = useState(false);
	const [deletingUser, setDeletingUser] = useState<DeletingUser>({
		...userDefaultValue,
		organizations: [],
	});
	const [deleteUserModalOpen, { open, close }] = useDisclosure(false);
	const [editingMember, setEditingMember] = useState<User>(userDefaultValue);
	const { Admin, SuperAdmin, Support, MasterAdmin } = UserRoles;
	const allowedToEditUsers = user.roles.some((role: UserRoles) =>
		[Admin, Support, SuperAdmin, MasterAdmin].includes(role)
	);
	const headerRef = useRef(null);

	// filter search bar
	const [searchValue, setSearchValue] = useState('');

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.currentTarget;
		setSearchValue(value);
		// filter data based off team name and user's firstName, lastName, email.
		const filteredMembersArray = members.filter((member) => {
			const lowercaseValue = value.toLowerCase();
			return (
				(member.firstName || '').toLowerCase().includes(lowercaseValue) ||
				(member.lastName || '').toLowerCase().includes(lowercaseValue) ||
				(member.email || '').toLowerCase().includes(lowercaseValue) ||
				member.roles.some((role) => role.toLowerCase().includes(lowercaseValue))
			);
		});
		setFilteredMembers(filteredMembersArray);
	};

	useEffect(() => {
		// only fire this if it's on the audit side
		if (auditSide) getUsersForOrg();
	}, [organizationID]);

	useEffect(() => {
		setFilteredMembers(members);
	}, [members]);

	useEffect(() => {
		// only fire this if it's on the audit side
		if (auditSide) getUsersForOrg();
	}, [organizationID]);

	const getUsersForOrg = async () => {
		try {
			setLoading(true);
			const members = await getUsersByOrg(organizationID);
			logger('info', 'Members for organization', members);
			setMembers(members);
			setFilteredMembers(members);
		} catch (error) {
			logger('error', 'Failed to get members for organization', error);
		} finally {
			setSearchValue('');
			setLoading(false);
		}
	};

	const editUser = (member: User) => {
		setEditingMember(member);
		setOpened(true);
	};

	const openRemoveUserFromOrgModal = (member: User) =>
		modals.openConfirmModal({
			title: 'Are you sure?',
			children: (
				<Text size='sm'>
					{`Pressing confirm below will remove ${member.email} from ${organization.name}. If you are certain then Confirm below.`}
				</Text>
			),
			labels: { confirm: 'Confirm', cancel: 'Keep User' },
			onConfirm: async () => {
				try {
					const deletedUser = await removeUserFromOrganization(
						member.id,
						member.currentOrganizationID
					);
					setMembers(
						members.filter(
							(member: User) => member.id !== deletedUser.data.data.userID
						)
					);
					logger('info', 'User deleted from org', deletedUser.data.data);
					showSuccessNotification({
						message: 'User successfully removed from organization.',
					});
				} catch (error) {
					logger('error', 'User failed to be deleted from org', error);
					showFailureNotification({
						message:
							'Failed to remove user from organization, please try again.',
					});
				}
			},
		});

	const openRemoveUserCompletelyModal = async (member: User) => {
		const userOrganizations = await searchOrganizationsByUserID(member.id);
		setDeletingUser({
			...member,
			organizations: userOrganizations.data.data,
		});
		open();
	};

	const ths = (
		<tr>
			<th>Name</th>
			<th>Email</th>
			<th>Roles</th>
			<th>Created At</th>
			<th></th>
		</tr>
	);

	const rows = (filteredMembers || []).map((member: User) => (
		<tr key={member.id} id={`VIS_ctf9nfdtmsxfgg2vmcmg_${getEnvForGleap()}`}>
			<td>
				{member.firstName || ''} {member.lastName || ''}
			</td>
			<td>{member.email}</td>
			<td>
				{(member?.roles || []).map((role: string) => {
					return roleBadge(role);
				})}
			</td>
			<td>
				{DateTime.fromISO(member.createdAt).toLocaleString(DateTime.DATE_MED)}
			</td>
			<td id={`VIS_ctf9nfdtmsxfgg2vmcn0_${getEnvForGleap()}`}>
				<Group>
					{allowedToEditUsers ? (
						<Menu
							shadow='md'
							width={200}
							position='right-start'
							withArrow
							arrowPosition='center'
						>
							<Menu.Target>
								<ActionIcon>
									<FontAwesomeIcon icon={faEllipsisVertical} />
								</ActionIcon>
							</Menu.Target>

							<Menu.Dropdown>
								<Menu.Label>Application</Menu.Label>
								<Menu.Item
									onClick={() => editUser(member)}
									icon={<FontAwesomeIcon icon={faUserPen} size={'xs'} />}
								>
									Edit User
								</Menu.Item>
								<Menu.Label>Danger zone</Menu.Label>
								<Menu.Item
									onClick={() => openRemoveUserFromOrgModal(member)}
									icon={<FontAwesomeIcon icon={faUserSlash} size={'xs'} />}
								>
									Remove from Org
								</Menu.Item>
								{user.roles.some((role) =>
									['support', 'masteradmin'].includes(role)
								) && (
									<Menu.Item
										onClick={() => openRemoveUserCompletelyModal(member)}
										color='red'
										icon={<FontAwesomeIcon icon={faTrash} size={'xs'} />}
									>
										Delete User
									</Menu.Item>
								)}
							</Menu.Dropdown>
						</Menu>
					) : null}
				</Group>
			</td>
		</tr>
	));

	return (
		<Stack h={'100%'} spacing={0}>
			<Group
				position='right'
				mb='xl'
				style={{ boxSizing: 'border-box' }}
				ref={headerRef}
				pt='sm'
			>
				<Group noWrap id={`VIS_ctf9nfdtmsxfgg2vmcng_${getEnvForGleap()}`}>
					<SearchBar
						searchValue={searchValue}
						placeholder={'Search members'}
						handleSearchChange={handleSearchChange}
					/>
					<CreateQuickAddUserModal orgID={organizationID} />
				</Group>
			</Group>
			<div
				style={{
					flex: 2,
					height: `calc(100% - ${
						headerRef?.current?.offsetHeight || 0
					}px - 24px)`,
					overflowY: 'hidden',
				}}
			>
				{loading ? (
					<ReelayLogoPageLoader />
				) : (
					<div
						style={{
							flex: 2,
							height: `100%`,
							overflowY: 'hidden',
						}}
					>
						<Paper radius='md' shadow='md' withBorder h={'100%'}>
							<ScrollArea h={'100%'}>
								<Table striped highlightOnHover>
									<thead>{ths}</thead>
									<tbody>{rows}</tbody>
								</Table>
							</ScrollArea>
						</Paper>
						<EditUserModal
							member={editingMember}
							opened={opened}
							setOpened={setOpened}
						/>
						<DeleteUserModal
							member={deletingUser}
							opened={deleteUserModalOpen}
							close={close}
						/>
					</div>
				)}
			</div>
		</Stack>
		// 24px is for mb="xl" on the group sibling element.
	);
}

const roleBadge = (role: string) => {
	switch (role) {
		case 'member':
			return (
				<Badge key={role} size='xs' color={'gray'}>
					Member
				</Badge>
			);
		case 'admin':
			return (
				<Badge key={role} size='xs' color={'blue'}>
					Admin
				</Badge>
			);
		case 'superadmin':
			return (
				<Badge key={role} size='xs' color={'grape'}>
					Super Admin
				</Badge>
			);
		case 'create-meeting':
			return (
				<Badge key={role} size='xs' color={'green'}>
					Create Meeting
				</Badge>
			);
		case 'invite-to-organization':
			return (
				<Badge key={role} size='xs' color={'teal'}>
					Invite to Organization
				</Badge>
			);
		case 'support':
			return (
				<Badge key={role} size='xs' color={'violet'}>
					Support
				</Badge>
			);
		case 'guest':
			return (
				<Badge key={role} size='xs' color={'orange'}>
					Guest
				</Badge>
			);
		case 'project-manager':
			return (
				<Badge key={role} size='xs' color='indigo'>
					Project Manager
				</Badge>
			);
	}
};
