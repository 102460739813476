import React, { useEffect, useState } from 'react';
import { createStyles, Loader, TextInput } from '@mantine/core';
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDebouncedValue } from '@mantine/hooks';
import { encode, decode } from 'js-base64';
import {getEnvForGleap} from "../../_utils/trackers";

interface Props {
	searchValue: string;
	handleSearch: (value: string) => void;
	viewType?: string;
}
export const MeetingSearch = ({
	searchValue: searchValueInitial = '',
	handleSearch = (s: string) => {
		return;
	},
	viewType,
}: Props) => {
	const [searchValue, setSearchValue] = useState(searchValueInitial || '');
	const [debounced] = useDebouncedValue(searchValue, 400);
	const [initialLoad, setInitialLoad] = useState(true);

	useEffect(() => {
		if (initialLoad) setInitialLoad(false);

		if (!initialLoad) debouncedHandleInputChange(debounced);
	}, [debounced]);

	// Create a debounced version of handleInputChange with a 300ms delay
	const debouncedHandleInputChange = async (value: string) => {
		if (typeof handleSearch === 'function') {
			handleSearch(value);
		}
	};

	return (
		<TextInput
			style={{ flex: 2 }}
			placeholder={'Search by meeting name...'}
			onChange={(e) => {
				setSearchValue(e.target.value);
			}}
			w={'100%'}
			value={searchValue}
			rightSection={<></>}
			onBlur={(e) => {
				e.preventDefault();
			}}
			mr={viewType === 'list' ? 10 : 0}
			id={`VIS_ctf9nfdtmsxfgg2vmcdg_${getEnvForGleap()}`}
		/>
	);
};
