import { logger } from '../helpers/logger';
import axios from 'axios';

const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_BASE_AI_SERVICE_URL, // Set your base URL
	timeout: 60000,
	// headers: {
	//   Authorization: 'Token ec45e9d491728fb3560b6237c817c830e3a988af',
	// },
});

// https://api.recall.ai/api/v1/bot/{{ _.recall_bot_id }}/
export const getRecallAIBotDetails = (recallBotID: string) => {
	return axiosInstance
		.get(`/recall-ai/bot/${recallBotID}/`, {
			headers: {
				Authorization: 'Token ec45e9d491728fb3560b6237c817c830e3a988af',
			},
		})
		.then((res) => {
			logger('info', 'Response received from getMeetingNotifications', res);
			return res?.data;
		})
		.catch((err) => {
			logger('error', 'Error getting user settings', err);
			return err;
		});
};
