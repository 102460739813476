import {
	Stack,
	Group,
	createStyles,
	ActionIcon,
	Indicator,
} from '@mantine/core';
import CardListViewToggle from '../CardListViewToggle';
import { MeetingSearch } from '../MeetingSearch';
import MeetingBulkActionRow from '../MeetingBulkActionRow';
import { useRecoilValue } from 'recoil';
import { meetingsView } from '../../Atoms/meetingAtom';
import { bulkDeletingCollectionMeetingsSelection } from '../../Atoms/collections';
import { DatePickerInput, DatePickerProps } from '@mantine/dates';
import { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { useParams } from 'react-router';
import {getEnvForGleap} from "../../_utils/trackers";

const useStyles = createStyles(() => ({
	topContainer: {
		flex: 1,
	},
}));

const statusOptions = [
	{ label: 'Live', value: 'live' }, //'published' },
	{ label: 'Upcoming', value: 'upcoming' }, //'scheduled,created' },
	{ label: 'Review', value: 'review' },
	{ label: 'Expired', value: 'expired' },
	{ label: 'All', value: 'all' },
];

export interface MeetingsHeaderProps {
	searchValue: string;
	handleSearch: (value: string) => void;
	opened: boolean;
	setOpened: (o: any) => void;
	bulkActionDeleteMeetings: () => void;
	handleBulkAddToCollection: (meetingIDs: string[]) => void;
	handleBulkArchive: (meetingIDs: string[]) => void;
	dateRange: [Date | null, Date | null];
	setDateRange: (dateRange: [Date | null, Date | null]) => void;
	segmentValue: string;
}

export default function MeetingsHeader({
	searchValue,
	handleSearch,
	opened,
	setOpened,
	bulkActionDeleteMeetings,
	handleBulkArchive,
	handleBulkAddToCollection,
	dateRange,
	setDateRange,
	segmentValue,
}: MeetingsHeaderProps) {
	const { classes } = useStyles();
	const viewType = useRecoilValue(meetingsView);
	const dateRef = useRef(null);
	const selection = useRecoilValue(bulkDeletingCollectionMeetingsSelection);

	const dayRenderer: DatePickerProps['renderDay'] = (date) => {
		const today = new Date().getDate();
		const day = date.getDate();
		return (
			<Indicator size={6} color='red' offset={-5} disabled={day !== today}>
				<div>{day}</div>
			</Indicator>
		);
	};

	const excludeDate = (date: Date): boolean => {
		const today = new Date();
		today.setHours(0, 0, 0, 0); // Normalize today's date to midnight for a fair comparison

		// If segmentValue is 'upcoming', disable all dates before today
		if (segmentValue === 'upcoming') {
			return date < today;
		}

		// If segmentValue is 'live', disable all dates after today
		if (segmentValue === 'live') {
			return date > today;
		}

		return false; // By default, do not disable dates if segmentValue is not 'upcoming' or 'live'
	};

	return (
		<Stack className={classes.topContainer} spacing={0}>
			<Group position={'apart'} align={'center'} spacing={0} noWrap>
				<Group align={'center'} style={{ flex: 2 }}>
					<CardListViewToggle />

					<MeetingSearch
						searchValue={searchValue}
						handleSearch={handleSearch}
						viewType={viewType}
					/>
				</Group>
				{viewType === 'list' ? (
					<MeetingBulkActionRow
						bulkActionDeleteMeetings={bulkActionDeleteMeetings}
						handleBulkArchive={handleBulkArchive}
						opened={opened}
						setOpened={setOpened}
						selection={selection}
						handleBulkAddToCollection={handleBulkAddToCollection}
					/>
				) : null}

				<DatePickerInput
					ref={dateRef}
					renderDay={dayRenderer}
					valueFormat='M/D/YYYY'
					allowSingleDateInRange
					variant={'unstyled'}
					type='range'
					clearable
					value={dateRange}
					onChange={setDateRange}
					excludeDate={(date: Date) => excludeDate(date)}
					icon={
						<ActionIcon variant='transparent'>
							<FontAwesomeIcon icon={faCalendar} color='black' style={{}} />
						</ActionIcon>
					}
					mx='auto'
					maw={400}
					firstDayOfWeek={0}
					styles={{
						wrapper: {
							position: 'relative',
							left: dateRange[0] && dateRange[1] ? '0px' : '3px',
						},
					}}
					id={`VIS_ctf9nfdtmsxfgg2vmcf0_${getEnvForGleap()}`}
				/>
			</Group>
		</Stack>
	);
}
