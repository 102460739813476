import {
	Button,
	Center,
	Container,
	Group,
	Loader,
	Paper,
	ScrollArea,
	Stack,
	Text,
	createStyles,
} from '@mantine/core';
import {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import MinutesCard from './MinutesCard';
import {
	createMeeting,
	filestackUpload,
	getMeetingMinutes,
} from '../../api/api';
import { useRecoilState, useRecoilValue } from 'recoil';
import { currentUser } from '../../Atoms/userAtoms';
import { filestackModalOpenedAtom } from '../../Atoms/filestack';
import { IMeeting } from '../../interfaces/meeting';
import { useDisclosure } from '@mantine/hooks';
import MeetingMinutesModal from '../../components/Modals/MeetingMinutesModal';
import { logger } from '../../helpers/logger';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../helpers/notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowDown } from '@fortawesome/pro-regular-svg-icons';
import FilestackPicker from '../../components/FilestackPicker/FilestackPicker';
import { PickerFileMetadata, PickerResponse } from 'filestack-js';
import { reloadMeetingsAtom } from '../../Atoms/meetingAtom';
import { getEnvForGleap } from '../../_utils/trackers';

const useStyles = createStyles(() => ({
	outerContainer: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
	},
}));

const COUNT_PER_PAGE = 20;

interface Props {
	buttonLoading: boolean;
	setButtonLoading: Dispatch<SetStateAction<boolean>>;
}

export default function MinutesPage({ buttonLoading }: Props) {
	const { classes } = useStyles();
	const headerRef = useRef(null);
	const user = useRecoilValue(currentUser);
	const [minutes, setMinutes] = useState<IMeeting[]>([]);
	const [offset, setOffset] = useState(0);
	const [total, setTotal] = useState(0);
	const [fetchingData, setFetchingData] = useState(false);
	const [meetingToShare, setMeetingToShare] = useState<IMeeting | null>(null);
	const [
		minutesModalOpened,
		{ open: openMinutesModal, close: closeMinutesModal },
	] = useDisclosure(false);
	const [filestackModalOpened, setFilestackModalOpened] = useRecoilState(
		filestackModalOpenedAtom
	);
	const reloadMeetings = useRecoilValue(reloadMeetingsAtom);

	const observer = useRef(null);
	const lastBookElementRef = useCallback(
		(node) => {
			if (observer.current) observer.current.disconnect();
			observer.current = new IntersectionObserver((entries) => {
				if (
					entries[0].isIntersecting &&
					!fetchingData &&
					offset + COUNT_PER_PAGE < total
				) {
					fetchMinutes({
						offset,
					});
				}
			});
			if (node) observer.current.observe(node);
		},
		[offset, total]
	);

	const seeUploadMeetingButton =
		user.roles.includes('create-meeting') &&
		!user.roles.includes('support') &&
		!user.roles.includes('masteradmin');

	const fetchMinutes = async ({ offset, increment = true }) => {
		try {
			if (fetchingData) return;
			setFetchingData(true);
			const newOffset = increment
				? Math.min(offset + COUNT_PER_PAGE, total)
				: offset;
			const query = {
				limit: COUNT_PER_PAGE,
				offset: newOffset,
				organizationID: user.currentOrganizationID,
			};
			const response = await getMeetingMinutes(query);
			const { data, count } = response?.data || {};
			if (newOffset === 0) setMinutes(data);
			else setMinutes((prevMinutes) => [...prevMinutes, ...data]);
			setOffset(newOffset);
			setTotal(count);
		} catch (error) {
			logger('error', 'failed to fetch minutes', error);
			showFailureNotification({
				message: 'Failed to fetch minutes',
			});
		} finally {
			setFetchingData(false);
		}
	};

	const handleUpload = () => {
		// opens filestack modal
		setFilestackModalOpened(true);
	};

	useEffect(() => {
		fetchMinutes({
			offset: 0,
			increment: false,
		});
	}, [reloadMeetings]);

	return (
		<>
			<div id='sharing-modals'>
				{meetingToShare ? (
					<MeetingMinutesModal
						opened={minutesModalOpened}
						close={closeMinutesModal}
						meeting={meetingToShare}
						isMinutesPage={true}
					/>
				) : null}
			</div>
			<Group grow position='apart' h={'100%'}>
				<Container className={classes.outerContainer} size={'xl'} pb={'xl'}>
					<Stack spacing={'xl'} h={'100%'}>
						<Group ref={headerRef} position={'apart'} align='center' noWrap>
							<Stack
								spacing={'xs'}
								id={`VIS_ctf9nfdtmsxfgg2vmcg0_${getEnvForGleap()}`}
							>
								<Text fw={700} fz={24} lh={'20px'}>
									{`Your Files (${minutes.length})`}
								</Text>
								<Text color='secondary-text' fz={14}>
									Meetings with no download/share options are still processing.
								</Text>
							</Stack>

							{seeUploadMeetingButton && (
								<Button
									onClick={handleUpload}
									radius={'md'}
									// data-disabled
									// sx={{ '&[data-disabled]': { pointerEvents: 'all' } }}
									size={'sm'}
									rightIcon={<FontAwesomeIcon icon={faCloudArrowDown} />}
									loading={buttonLoading}
									id={`VIS_ctf9nfdtmsxfgg2vmck0_${getEnvForGleap()}`}
								>
									{buttonLoading ? 'Uploading' : 'Upload'}
								</Button>
							)}
						</Group>
						<Paper
							h={`calc(100% - ${headerRef?.current?.clientHeight || 75}px)`}
							radius='lg'
							p='xl'
							shadow='md'
						>
							<ScrollArea h={'100%'} scrollbarSize={10} offsetScrollbars>
								<Stack spacing='lg' w={'100%'}>
									{minutes.map((meeting, index) => {
										if (minutes.length === index + 1) {
											return (
												<div ref={lastBookElementRef} key={meeting.id}>
													<MinutesCard
														key={meeting.id}
														meeting={meeting}
														openMinutesModal={openMinutesModal}
														setMeetingToShare={setMeetingToShare}
														setMinutes={setMinutes}
													/>
												</div>
											);
										} else {
											return (
												<MinutesCard
													key={meeting.id}
													meeting={meeting}
													openMinutesModal={openMinutesModal}
													setMeetingToShare={setMeetingToShare}
													setMinutes={setMinutes}
												/>
											);
										}
									})}
									{fetchingData ? (
										<Center>
											<Loader />
										</Center>
									) : null}
								</Stack>
							</ScrollArea>
						</Paper>
					</Stack>
				</Container>
			</Group>
		</>
	);
}
