import React, { MutableRefObject, useEffect, useState } from 'react';
import {
	Chip,
	createStyles,
	Dialog,
	Group,
	Stack,
	Text,
	Grid,
	Textarea,
	Checkbox,
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/pro-light-svg-icons';
import { faLock } from '@fortawesome/pro-solid-svg-icons';
import CustomChip from '../../CustomChip';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
	currentMeetingHighlights as HighlightAtom,
	momentDialogOpened,
	videoPlayerCurrentTime,
	videoPlayerElapsedTime,
} from '../../../../Atoms/meetingAtom';
import { useClickOutside } from '@mantine/hooks';
import { useForm } from '@mantine/form';
import {
	createMeetingHighlight,
	updateMeetingHighlight,
} from '../../../../api/api';
import { IMeeting } from '../../../../interfaces/meeting';
import { CreateHighlightButton } from '../../components/CreateHighlightButton';
import {
	HighlightType,
	sortHighlightsByTimeStartMS,
} from '../../../../helpers/highlights';
import {
	creatingHighlightAtom,
	editingHighlightAtom,
	editingHighlightID as editingHighlightIDAtom,
	editingHighlightInitialValuesAtom,
} from '../../../../Atoms/userAtoms';
import {
	convertTimeToSeconds,
	formatSecondsToMinutesAndSeconds,
} from '../../../../_utils/time';
import TimestampInput from '../../../TimestampInput';
import { logger } from '../../../../helpers/logger';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../../helpers/notifications';
import { MILLISECONDS_PER_SECOND } from '../../../constants';

const useStyles = createStyles((theme) => ({
	cardText: {
		fontSize: 12,
		color: theme.colors?.primary[5],
	},
	title: {
		color: theme.colors?.['primary-text'],
		fontWeight: 600,
		fontSize: 18,
	},
	topBar: {
		marginTop: '24px',
	},
	toolTip: {
		fontSize: 12,
	},
	labels: {
		color: theme.colors?.['secondary-text'],
		fontSize: '10px',
		lineHeight: '14px',
		fontWeight: 600,
	},
	validationError: {
		color: '#fa5252',
		fontSize: '10px',
		lineHeight: 1.2,
		display: 'block',
	},
}));

interface CreateHighlightDialogProps {
	cardRef?: MutableRefObject<HTMLDivElement>;
	meeting: IMeeting;
	allowedTypes: HighlightType[];
	initialTypeValue?: string;
	title?: string;
	buttonText?: string;
}

export const CreateHighlightDialog = ({
	cardRef,
	meeting,
	allowedTypes,
	initialTypeValue = '',
	title = 'New Highlight',
	buttonText,
}: CreateHighlightDialogProps) => {
	const { classes } = useStyles();
	const [isPrivate, setIsPrivate] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);
	// elapsedTime is what the user sees - string of 'mm:ss'
	const elapsedTime = useRecoilValue(videoPlayerElapsedTime);
	const [opened, setOpened] = useRecoilState(momentDialogOpened);
	// currentTime is number type and what we use and pass to backend.
	// it is the current time of video player. so 0:01 means 1.
	const currentTime = useRecoilValue(videoPlayerCurrentTime);
	const [currentHighlights, setCurrentHighlights] =
		useRecoilState(HighlightAtom);
	const [editingHighlight, setEditingHighlight] =
		useRecoilState(editingHighlightAtom);
	const setCreatingHighlight = useSetRecoilState(creatingHighlightAtom);
	const editingHighlightInitialValues = useRecoilValue(
		editingHighlightInitialValuesAtom
	);
	const editingHighlightID = useRecoilValue(editingHighlightIDAtom);
	const { content, type, visibility, timeStartMS, isTopQuestion } =
		editingHighlightInitialValues;
	const timeStartInSeconds = timeStartMS / 1000;
	const [highlightTimestamp, setHighlightTimestamp] = useState(
		formatSecondsToMinutesAndSeconds(timeStartInSeconds)
	);
	const [createHighlightTimestamp, setCreateHighlightTimestamp] = useState(
		formatSecondsToMinutesAndSeconds(currentTime)
	);
	const [timestampExceedsVideoDuration, setTimestampExceedsVideoDuration] =
		useState(false);

	const ref = useClickOutside(() => {
		setEditingHighlight(false);
		setCreatingHighlight(false);
		setOpened(false);
		if (editingHighlight) {
			form.reset();
		}
	});

	const form = useForm({
		initialValues: {
			content: content || '',
			type: editingHighlight ? type : initialTypeValue || '',
			isTopQuestion: isTopQuestion ?? null,
		},
		validate: {
			content: (value) => (value.length ? null : 'Content is required.'),
			type: (value) => (value.length ? null : 'Type is required.'),
		},
	});

	useEffect(() => {
		form.setValues({
			content,
			type: editingHighlight
				? type
				: type.length
				? type
				: initialTypeValue || '',
			isTopQuestion: isTopQuestion ?? null,
		});
		setIsPrivate(visibility === 'personal');
		setHighlightTimestamp(formatSecondsToMinutesAndSeconds(timeStartInSeconds));
	}, [editingHighlightInitialValues]);

	useEffect(() => {
		setCreateHighlightTimestamp(elapsedTime);
	}, [elapsedTime]);

	useEffect(() => {
		if (form.values['type'] !== 'question') {
			form.setFieldValue('isTopQuestion', false);
		}
	}, [form.values['type']]);

	const createHighlight = async (values: {
		content: string;
		type: string;
		isTopQuestion: boolean | null;
	}) => {
		if (timestampExceedsVideoDuration) {
			showFailureNotification({
				message: 'Start time cannot be greater than video duration.',
			});
			return;
		}
		setButtonLoading(true);
		const timeInMS = convertTimeToSeconds(createHighlightTimestamp) * 1000;
		try {
			const payload = {
				timeStartMS: timeInMS || 0,
				timeEndMS: timeInMS || 0,
				...values,
				visibility: isPrivate ? 'personal' : 'group',
			};
			const highlight = await createMeetingHighlight(
				payload,
				meeting.id,
				meeting.organizationID
			);
			setCreatingHighlight(false);
			showSuccessNotification({
				message: 'Highlight was successfully created! Great work!',
			});
			setCurrentHighlights(
				sortHighlightsByTimeStartMS([highlight, ...currentHighlights])
			);
			form.reset();
			setCreateHighlightTimestamp(
				formatSecondsToMinutesAndSeconds(currentTime)
			);
			setIsPrivate(false);
			setOpened(false);
		} catch (error: unknown) {
			logger('error', 'Error creating highlight', error);
			showFailureNotification({
				message:
					'We apologize, but there was an error while creating the highlight. Please try again, and if the issue persists, kindly contact our support team for assistance.',
			});
		} finally {
			setButtonLoading(false);
		}
	};

	const updateHighlight = async (values: { content: string; type: string }) => {
		console.log('updateHighlight values', values);
		if (timestampExceedsVideoDuration) {
			showFailureNotification({
				message: 'Start time cannot be greater than video duration.',
			});
			return;
		}
		setButtonLoading(true);

		try {
			const highlightTime =
				convertTimeToSeconds(highlightTimestamp) * MILLISECONDS_PER_SECOND;
			console.log('highlightTime', highlightTime);
			const payload = {
				timeStartMS: highlightTime || 0,
				timeEndMS: highlightTime || 0,
				...values,
				visibility: isPrivate ? 'personal' : 'group',
			};
			console.log('payload', payload);
			const highlight = await updateMeetingHighlight({
				data: {
					...payload,
				},
				meetingID: meeting.id,
				meetingHighlightID: editingHighlightID,
				organizationID: meeting.organizationID,
			});

			console.log('highlight', highlight);

			showSuccessNotification({
				message: 'Moment was successfully updated! Great work!',
			});

			const newHighlightsAndActions = currentHighlights.map((item) =>
				item.id === highlight.id ? highlight : item
			);
			console.log('newHighlightsAndActions', newHighlightsAndActions);
			const sortedHighlights = sortHighlightsByTimeStartMS(
				newHighlightsAndActions
			);
			console.log('sortedHighlights', sortedHighlights);
			setCurrentHighlights(sortedHighlights);
			form.reset();
			setIsPrivate(false);
			setEditingHighlight(false);
			setOpened(false);
		} catch (error: unknown) {
			logger('error', 'Error updating highlight', error);
			showFailureNotification({
				message:
					'We apologize, but there was an error while updating the highlight. Please attempt the update again, and if you continue to face issues, feel free to contact our support team for assistance.',
			});
		} finally {
			setButtonLoading(false);
		}
	};

	return (
		<Dialog
			opened={opened}
			onClose={() => {
				setCreatingHighlight(false);
				form.reset();
				setOpened(false);
			}}
			withCloseButton
			size='lg'
			radius='md'
			transition={'slide-up'}
			position={{ bottom: 8, right: 16 }}
			p={16}
			w={cardRef?.current?.offsetWidth}
			sx={{ zIndex: editingHighlight ? 500 : 0 }}
		>
			<form
				onSubmit={form.onSubmit((values) =>
					editingHighlight ? updateHighlight(values) : createHighlight(values)
				)}
			>
				<Stack spacing={24} ref={ref}>
					<Group position={'apart'} className={classes.topBar} align={'center'}>
						<Text className={classes.title}>{title}</Text>
						<Group position={'right'} align={'center'} spacing={4}>
							{isPrivate ? (
								<FontAwesomeIcon icon={faLock} size={'xs'} />
							) : (
								<FontAwesomeIcon icon={faGlobe} size={'xs'} />
							)}
							<Text className={classes.cardText}>
								{isPrivate ? 'Personal' : 'Public'}
							</Text>
							{/*<Switch*/}
							{/*  checked={isPrivate}*/}
							{/*  onChange={(event) => setIsPrivate(event.currentTarget.checked)}*/}
							{/*  size={'xs'}*/}
							{/*  styles={{*/}
							{/*    root: {*/}
							{/*      display: 'flex',*/}
							{/*      alignItems: 'center',*/}
							{/*      width: '40px',*/}
							{/*    },*/}
							{/*  }}*/}
							{/*/>*/}
						</Group>
					</Group>
					<Grid>
						<Grid.Col span={3}>
							<Stack spacing={8}>
								<TimestampInput
									value={
										editingHighlight
											? highlightTimestamp
											: createHighlightTimestamp
									}
									setValue={
										editingHighlight
											? setHighlightTimestamp
											: setCreateHighlightTimestamp
									}
									duration={meeting?.videoMetadata?.duration}
									error={timestampExceedsVideoDuration}
									setError={setTimestampExceedsVideoDuration}
								/>
							</Stack>
						</Grid.Col>
						<Grid.Col span={9}>
							<Textarea
								{...form.getInputProps('content')}
								placeholder='Your comment'
								radius='md'
								size='xs'
								autosize
								minRows={2}
								maxRows={6}
							/>
						</Grid.Col>
					</Grid>
					<Stack spacing={8}>
						<Group position='apart'>
							<Text className={classes.labels}>Moment Type</Text>
							{form.values['type'] === 'question' && (
								<Text className={classes.labels}>Top Question</Text>
							)}
						</Group>
						<Group position='apart' noWrap>
							<Chip.Group {...form.getInputProps('type')}>
								<Group w={'100%'} position={'left'}>
									<Group position='apart' noWrap spacing={'sm'}>
										{allowedTypes.map(({ value, label, color }) => (
											<CustomChip
												key={value}
												color={color}
												size={'xs'}
												value={value}
												label={label}
											/>
										))}
									</Group>
								</Group>
							</Chip.Group>
							{form.values['type'] === 'question' && (
								<Checkbox
									{...form.getInputProps('isTopQuestion', {
										type: 'checkbox',
									})}
								/>
							)}
						</Group>
						{'type' in form.errors && form.values['type']?.length < 1 && (
							<Text className={classes.validationError}>Type is required.</Text>
						)}
					</Stack>
					<CreateHighlightButton
						loading={buttonLoading}
						buttonText={buttonText}
					/>
				</Stack>
			</form>
		</Dialog>
	);
};
