import React, { useState } from 'react';
import {
	Button,
	TextInput,
	Group,
	Title,
	Container,
	createStyles,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useForm } from '@mantine/form';
import { registerUser } from '../../api/api';
import {
	defaultFailureNotificationProps,
	defaultSuccessNotificationProps,
} from '../constants';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../helpers/notifications';

const useStyles = createStyles((theme) => ({
	label: {
		textAlign: 'left',
	},

	controls: {
		[theme.fn.smallerThan('xs')]: {
			flexDirection: 'column-reverse',
		},
	},

	control: {
		[theme.fn.smallerThan('xs')]: {
			width: '100%',
			textAlign: 'center',
		},
	},
}));

export default function CreateUserModal() {
	const { classes } = useStyles();

	// const [opened, setOpened] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | null>();

	async function createUser(data: any) {
		setLoading(true);
		try {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const response = await registerUser(data);
			setLoading(false);
			// setOpened(false);
			showSuccessNotification({
				message: 'User was successfully created! Great work!',
			});
		} catch (err: any) {
			setError(err.message);
			setLoading(false);
			showFailureNotification({
				message:
					'Sorry, failed to create a user. That user may already exist. Please try again.',
			});
		}
	}
	const form = useForm({
		initialValues: {
			email: '',
			firstName: '',
			lastName: '',
		},
		validate: {
			email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
		},
	});
	return (
		<>
			<Container>
				<Group spacing={'xl'}>
					<Title order={3}>Create a User</Title>
				</Group>
				<form onSubmit={form.onSubmit((values) => createUser(values))}>
					<TextInput
						placeholder={'gavin.belson@hooli.com'}
						label={'Email'}
						className={classes.label}
						{...form.getInputProps('email')}
					/>
					<TextInput
						placeholder={'Gavin'}
						label={'First Name'}
						className={classes.label}
						{...form.getInputProps('firstName')}
					/>
					<TextInput
						placeholder={'Belson'}
						label={'Last Name'}
						className={classes.label}
						{...form.getInputProps('lastName')}
					/>
					<br />
					<Group position='right' mt='lg' className={classes.controls}>
						<Button
							type='submit'
							loading={loading}
							className={classes.control}
							radius={'md'}
						>
							Create User
						</Button>
					</Group>
				</form>
			</Container>

			{/*<Modal opened={opened} onClose={() => setOpened(false)} title={'Create a User'}>*/}
			{/*  <form onSubmit={form.onSubmit((values) => createUser(values))}>*/}
			{/*    <TextInput placeholder={'human@reelay.ai'} label={'Email'} {...form.getInputProps('email')}/>*/}
			{/*    <TextInput placeholder={'Notta'} label={'First Name'} {...form.getInputProps('firstName')}/>*/}
			{/*    <TextInput placeholder={'Robot'} label={'Last Name'} {...form.getInputProps('lastName')}/>*/}
			{/*    <br/>*/}
			{/*    <Group>*/}
			{/*      { (!loading) ? <Button type="submit">Create User</Button> : <Button type="submit" loading>Create User</Button>}*/}
			{/*    </Group>*/}
			{/*  </form>*/}
			{/*</Modal>*/}
			{/*<Group>*/}
			{/*  <Button onClick={() => setOpened(true)}>+</Button>*/}
			{/*</Group>*/}
		</>
	);
}
