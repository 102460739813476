import { Loader, Modal, ScrollArea, Stack } from '@mantine/core';
import ModalTitle from '../../Titles/ModalTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IMeeting } from '../../../interfaces/meeting';
import { fetchMembers } from '../../../helpers/teams';
import {
	getMeetingInvitedUsers,
	getUsersByOrg,
	removeUsersFromAMeeting,
} from '../../../api/api';
import {
	User,
	currentUser,
	userOrganizationMembers,
} from '../../../Atoms/userAtoms';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { logger } from '../../../helpers/logger';
import {
	currentMeeting,
	currentMeetingDistributionList,
} from '../../../Atoms/meetingAtom';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../helpers/notifications';
import UserList from '../../UserList';
import { InvitedUser } from '../../../interfaces/user';
import { faUserGear } from '@fortawesome/pro-solid-svg-icons';
import useDistributionList from '../../../customHooks/useDistributionList';

interface Props {
	opened: boolean;
	close: () => void;
}

export default function ManageAccessModal({ close, opened }: Props) {
	const meeting = useRecoilValue(currentMeeting);
	const { organizationID, id } = meeting;
	const user = useRecoilValue(currentUser);
	const orgMembers = useRecoilValue(userOrganizationMembers);
	const { distributionList, loading, setDistributionList } =
		useDistributionList(organizationID, id);

	const handleRemoveUser = async (email: string) => {
		if (!email) {
			showFailureNotification({
				message:
					'Sorry, we encountered an issue while trying to remove the user from the meeting. Please give it another try.',
			});
			return;
		}
		try {
			logger('info', 'remove user from meeting', {
				meetingID: id,
				organizationID,
				email,
			});
			const res = await removeUsersFromAMeeting([email], id, organizationID);
			const newInvitedUsers = (res?.data?.data?.invitedUsers || []).filter(
				(user: InvitedUser) => user.id !== meeting.owningUserID
			);
			setDistributionList(newInvitedUsers);
			showSuccessNotification({
				message: 'User successfully removed from the meeting.',
			});
		} catch (error) {
			showFailureNotification({
				message:
					'Sorry, we encountered an issue while trying to remove the user from the meeting. Please give it another try.',
			});
			logger('error', 'error removing user from meeting:', {
				meetingID: id,
				organizationID,
				email,
				error,
			});
		}
	};

	const userData = orgMembers
		.map((user: User) => ({
			...user,
			label: user.email,
			value: user.email,
			key: user.id,
		}))
		.filter((item: User, index, array) => {
			return (
				user.id !== item.id &&
				array.findIndex((i) => i.id === item.id) === index
			);
		});

	return (
		<Modal
			opened={opened}
			onClose={close}
			title={
				<ModalTitle
					text='Manage Access'
					icon={<FontAwesomeIcon icon={faUserGear} />}
				/>
			}
			size={'lg'}
			zIndex={10000}
			radius={'lg'}
			withinPortal={false}
			styles={(theme) => ({
				header: {
					borderRadius: theme.spacing.lg,
				},
				body: {
					overflowY: 'auto',
				},
			})}
			scrollAreaComponent={ScrollArea.Autosize}
		>
			{loading ? (
				<Loader />
			) : (
				<Stack spacing={'lg'} h={'100%'}>
					<UserList
						searchValue={''}
						users={distributionList}
						gridColSpan={12}
						emptyText={'You have no viewers to manage.'}
						handleRemoveUser={handleRemoveUser}
					/>
				</Stack>
			)}
		</Modal>
	);
}
