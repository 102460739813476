import axiosInstance from '../axios/axios';
import { logger } from '../helpers/logger';

export const removeBotFromCalendarEvent = async (
	recallEventID: string,
	organizationID: string,
	eventID: string
) => {
	logger('info', 'Request sent to removeBotFromCalendarEvent', {
		recallEventID,
	});
	return axiosInstance.delete(`v1/calendar/${eventID}/remove-bot`, {
		data: {
			recallEventID,
		},
		headers: { 'x-organization-id': organizationID },
	});
};

export const scheduleBotForCalendarEvent = async (
	recallEventID: string,
	meetingID: string,
	organizationID: string
	// eventID: string
) => {
	logger('info', 'Request sent to removeBotFromCalendarEvent', {
		recallEventID,
		meetingID,
	});
	return axiosInstance.post(
		`v1/calendar/${meetingID}/schedule-bot`,
		{
			recallEventID,
			meetingID,
		},
		{
			headers: { 'x-organization-id': organizationID },
		}
	);
};
