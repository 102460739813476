import { ScrollArea, Stack, createStyles } from '@mantine/core';
import { useRecoilState, useRecoilValue } from 'recoil';
import { meetingAttachments } from '../../../../../Atoms/meetingAtom';
import { currentUser } from '../../../../../Atoms/userAtoms';
import { IMeeting } from '../../../../../interfaces/meeting';
import { isMeetingOwnerOrProjectManager } from '../../../../../helpers/auth';
import { removeHtmlTags } from '../../../../../_utils/handy-functions';

const useStyles = createStyles((theme) => ({}));

interface Props {
	meeting: IMeeting;
}

export default function LongSummaryTabContent({ meeting }: Props) {
	const { classes, theme } = useStyles();
	const [attachments, setAttachments] = useRecoilState(meetingAttachments);
	const user = useRecoilValue(currentUser);
	const { longSummary, organizationID, id, owningUserID } = meeting;
	const isOwnerOrPM = isMeetingOwnerOrProjectManager(
		owningUserID,
		user.id,
		user.roles
	);

	return (
		<Stack
			h={'100%'}
			w={'100%'}
			style={{
				justifyContent: 'flex-start',
			}}
		>
			<ScrollArea
				p={'md'}
				pb={0}
				lh={'25px'}
				h={'100%'}
				type='always'
				offsetScrollbars
				scrollbarSize={10}
				mih={100}
				styles={{
					root: {
						flex: 2,
					},
					viewport: {
						overflowX: 'hidden',
						width: '100%',
						maxWidth: '100%',
						fontSize: 14,
						fontWeight: 400,
						lineHeight: '25px',
					},
				}}
			>
				{removeHtmlTags(longSummary)}
			</ScrollArea>
		</Stack>
	);
}
