import { Paper, Stack, Switch, Text, Title, Tooltip } from '@mantine/core';
import { styles } from '../../styles';
import useUserSettings from '../../../../../customHooks/useUserSettings';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../../../helpers/notifications';
import { logger } from '../../../../../helpers/logger';
import { useState } from 'react';

export default function SharingCard() {
	const { classes } = styles();
	const { userSettings, updateUserSettings } = useUserSettings();
	const [checked, setChecked] = useState<boolean>(userSettings?.sharing);

	const handleSharingChange = async (value: boolean) => {
		try {
			setChecked(value);
			const res = await updateUserSettings([
				{
					key: 'sharing',
					value,
				},
			]);
			const details = res[0];
			showSuccessNotification({
				message: 'You have successfully changed the sharing setting.',
			});
		} catch (error) {
			logger('error', 'error changing sharing', error);
			showFailureNotification({
				message:
					'Sorry, there was an error changing the sharing setting. Please try again.',
			});
		}
	};

	return (
		<Paper className={classes.card} shadow='xs' p='md' radius={'md'}>
			<Stack>
				<Switch
					labelPosition='left'
					label={
						<Title order={4} color='primary-text'>
							Sharing Default
						</Title>
					}
					description={
						<Text
							style={{
								cursor: 'default',
							}}
						>
							Enable this setting to have new meetings created with sharing preference set to {' '}
							<Text span fs='italic'>
								Anyone with Link
							</Text>
							. Otherwise the default will be{' '}
							<Text span fs='italic'>
								Invite only
							</Text>
							.
						</Text>
					}
					size='sm'
					w={'100%'}
					width={'100%'}
					mb={'lg'}
					checked={checked}
					onChange={(e) => handleSharingChange(e.currentTarget.checked)}
					styles={{
						body: {
							width: '100%',
							justifyContent: 'space-between',
							flex: 1,
						},
					}}
				/>
			</Stack>
		</Paper>
	);
}
