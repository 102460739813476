import React, { useState } from 'react';
import {
	Modal,
	Group,
	Button,
	TextInput,
	Stack,
	Title,
	Text,
	Paper,
	Select,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import {
	createOrganization,
	getAllOrganizations,
	inviteUserToOrganization,
	registerUser,
} from '../../api/api';
import {
	defaultFailureNotificationProps,
	defaultSuccessNotificationProps,
	roles,
} from '../constants';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
	Organization,
	allSupportUsers,
	defaultOrganization,
	organizations as organizationsAtom,
} from '../../Atoms/organizationAtom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { UsersMultiSelect } from '../UsersMultiSelect';
import { userOrganizationMembers } from '../../Atoms/userAtoms';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { RoleBadge } from '../Badges/RoleBadge';
import { logger } from '../../helpers/logger';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../helpers/notifications';
import { assignSupporterToOrganization } from '../../api/organizations';

export default function CreateOrganizationModal(): JSX.Element {
	const [organizations, setOrganizations] = useRecoilState(organizationsAtom);
	const [users, setUsers] = useRecoilState(userOrganizationMembers);
	const supportUsers = useRecoilValue(allSupportUsers);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | null>();
	const [page, setPage] = useState(0);
	const [usersAdded, setUsersAdded] = useState([]);
	const [value, setValue] = useState<string | null>(null);
	const [newOrganization, setNewOrganization] =
		useState<Organization>(defaultOrganization);

	const selectData = supportUsers.map((user) => ({
		label: `${user.firstName} ${user.lastName} - ${user.email}`,
		value: user.id,
	}));

	async function handleCreateOrganization(data: { name: string }) {
		setLoading(true);
		try {
			const response = await createOrganization(data);
			const newOrg = {
				...response.data.data,
				supportLinks: [],
			};
			setOrganizations([...organizations, newOrg]);
			setNewOrganization(newOrg);
			setPage(2);
		} catch (err) {
			logger('error', 'Error creating organization', err);
			setError(err?.message);
			showFailureNotification({
				message:
					'Sorry, there was an error when creating an organization. Please try again.',
			});
		} finally {
			setLoading(false);
		}
	}

	const form = useForm({
		initialValues: {
			name: '',
		},
		validate: {
			name: (value) => (value.length === 0 ? 'Name is required.' : null),
		},
	});

	const userForm = useForm({
		initialValues: {
			firstName: '',
			lastName: '',
			email: '',
			roles: [],
		},
		validate: {
			firstName: (value) => (value.length ? null : 'Invalid first name'),
			lastName: (value) => (value.length ? null : 'Invalid last name'),
			email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
			roles: (value) => (value.length ? null : 'A role is required.'),
		},
	});
	async function addUser(data) {
		// setLoading(true);
		setUsersAdded((usersAdded) => [...usersAdded, data]);
		userForm.reset();
	}

	const sendUsers = async () => {
		try {
			setLoading(true);
			// create users
			await Promise.allSettled([
				...usersAdded.map((user) => registerUser(user)),
			]);
			logger('info', 'combinedUserData', { usersAdded });

			const response = await Promise.all([
				...usersAdded.map((user) =>
					inviteUserToOrganization(user, newOrganization.id)
				),
			]);
			const newUsersAdded = response.map((user) => user.data.data.user);
			setUsers([...users, ...newUsersAdded]);
			setUsersAdded([]);

			// assign support
			const res = await assignSupporterToOrganization(
				value,
				newOrganization.id
			);
			if (res.code === 'ERR_BAD_REQUEST') {
				showFailureNotification({
					message:
						'Failed to assign support user. The user may already be assigned to this organization.',
				});
				return;
			}
			const organizations = await getAllOrganizations();
			logger('info', 'Retrieved organizations', organizations);
			const newOrganizations = organizations.data.data;
			setOrganizations(newOrganizations);
			setValue('');
			form.reset();
			userForm.reset();
			setLoading(false);
			setPage(0);
			showSuccessNotification({
				message: 'Users successfully invited! Great job!',
			});
		} catch (err) {
			logger('error', 'Error inviting users', err);
			setError(err?.message);
			showFailureNotification({
				message:
					'Sorry, there was an error when inviting users. Please try again.',
			});
		} finally {
			setLoading(false);
		}
	};

	const deleteUser = (index: number) => {
		const newUsers = [...usersAdded];
		newUsers.splice(index, 1);
		setUsersAdded(newUsers);
	};

	const resetFormsAndPageState = () => {
		setPage(0);
		form.reset();
		userForm.reset();
		setUsersAdded([]);
		setValue('');
	};

	return (
		<>
			{/*1st step modal*/}
			<Modal
				centered={true}
				opened={page === 1}
				onClose={resetFormsAndPageState}
				title='Create an Organization'
			>
				<form
					onSubmit={form.onSubmit((values) => handleCreateOrganization(values))}
				>
					<TextInput
						data-autofocus
						label={'Name'}
						{...form.getInputProps('name')}
					/>
					<Group position='right' mt='lg'>
						<Button type='submit' loading={loading}>
							Next
						</Button>
					</Group>
				</form>
			</Modal>

			{/*2nd step modal*/}
			<Modal
				opened={page === 2}
				centered={true}
				onClose={resetFormsAndPageState}
				title={<Title order={5}>Invite Users</Title>}
				size={'lg'}
			>
				<Stack>
					<Stack spacing={0}>
						<Text color={'secondary-text'} size={12}>
							At least one user is required.
						</Text>
						<Text>Users Added</Text>
						{usersAdded.map((user, index) => (
							<Paper key={user.email} shadow='xs' p='sm' w={'100%'}>
								<Group position={'apart'} noWrap>
									<Stack spacing={'xs'}>
										<Text size='sm' weight={500}>
											{`${user.firstName} ${user.lastName}`}
										</Text>
										<Text color='dimmed' size='xs'>
											{user.email}
										</Text>
										<Group noWrap spacing={'xs'}>
											{user.roles.map((role) => (
												<RoleBadge role={role} />
											))}
										</Group>
									</Stack>
									<FontAwesomeIcon
										style={{ cursor: 'pointer' }}
										icon={faTrash}
										size={'xs'}
										color={'red-fill'}
										onClick={() => deleteUser(index)}
									/>
								</Group>
							</Paper>
						))}
					</Stack>

					<Stack>
						<form onSubmit={userForm.onSubmit((values) => addUser(values))}>
							<TextInput
								data-autofocus
								mb='sm'
								label={'First Name'}
								{...userForm.getInputProps('firstName')}
							/>
							<TextInput
								mb='sm'
								label={'Last Name'}
								{...userForm.getInputProps('lastName')}
							/>
							<TextInput
								mb='sm'
								label={'Email'}
								{...userForm.getInputProps('email')}
							/>
							<UsersMultiSelect
								errorText='A role is required.'
								data={roles}
								labelText={'Roles'}
								placeholder='Select Roles'
								name={'roles'}
								form={userForm}
							/>
							<Button mt={12} radius={'md'} type='submit'>
								Add
							</Button>
						</form>

						<Stack spacing={0}>
							<Text color={'secondary-text'} size={12}>
								Assigning a support user is required.
							</Text>
							<Select
								value={value}
								onChange={setValue}
								defaultValue={value}
								data={selectData}
								placeholder='Select Support User'
								label={'Support User'}
								required
							/>
						</Stack>
						<Group mt={5} position={'right'}>
							<Button
								color={'secondary-text'}
								radius={'md'}
								onClick={() => setPage(1)}
							>
								Back
							</Button>
							<Button
								radius={'md'}
								loading={loading}
								disabled={usersAdded.length === 0 || !value}
								onClick={sendUsers}
							>
								Create Organization
							</Button>
						</Group>
					</Stack>
				</Stack>
			</Modal>
			<Group position='right'>
				<Button
					radius={'lg'}
					leftIcon={<FontAwesomeIcon icon={faPlus} />}
					onClick={() => setPage(1)}
					m={'lg'}
				>
					Create an Organization
				</Button>
			</Group>
		</>
	);
}
