import React from 'react';
import {
	Avatar,
	createStyles,
	Group,
	Stack,
	Text,
	Grid,
	Tooltip,
	ActionIcon,
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faAt,
	faBadgeCheck,
	faPaperPlane,
	faX,
} from '@fortawesome/pro-solid-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import reactStringReplace from 'react-string-replace';
import { useRecoilValue } from 'recoil';
import {
	currentMeeting,
	currentMeetingDistributionList,
} from '../../../Atoms/meetingAtom';
import { convertEpochToDate } from '../../../_utils/time';
import { userSettings } from '../../../Atoms/settings';
import { inviteUsersToMeeting } from '../../../api/api';
import {
	currentUser,
	isProjectManagerAtom,
	userOrganizationMembers,
} from '../../../Atoms/userAtoms';

const useStyles = createStyles((theme) => ({
	card: {
		padding: 10,
	},
	icon: {
		size: '12px',
		color: '#ADB5BD',
	},
	userName: {
		fontSize: 14,
		fontWeight: 400,
		lineHeight: '20px',
		letterSpacing: 0,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	hostInfo: {
		fontSize: 12,
		fontWeight: 400,
		lineHeight: '22px',
		letterSpacing: 0,
		color: '#868E96',
	},
	email: {
		color: '#6B778C',
		fontSize: 12,
		fontWeight: 400,
		lineHeight: '22px',
	},
	linkedInIcon: {
		'&:hover': {
			color: '#0072b1',
		},
	},
	deleteIcon: {
		'&:hover': {
			color: 'red',
		},
	},
}));

interface Props {
	email: string | null;
	firstName: string;
	lastName: string;
	gridColSpan: number;
	searchValue: string;
	handleRemoveUser?: (email: string) => void;
	inviteUser?: ({
		email,
		firstName,
		lastName,
	}: {
		email: string;
		firstName: string;
		lastName: string;
	}) => void;
	isAttendees?: boolean;
	showLinkedIn?: boolean;
	userID?: string | null;
	owningUserID?: string | null;
	expiresAt?: number | null;
	hideIcons?: boolean;
}

const UserCard = ({
	email,
	firstName,
	lastName,
	gridColSpan,
	handleRemoveUser,
	inviteUser,
	searchValue = '',
	isAttendees = false,
	showLinkedIn = true,
	userID = null,
	owningUserID = null,
	expiresAt = null,
	hideIcons = false,
}: Props) => {
	const { classes, theme } = useStyles();
	const user = useRecoilValue(currentUser);
	const userSettingsObj = useRecoilValue(userSettings);
	const distributionList = useRecoilValue(currentMeetingDistributionList);
	const orgMembers = useRecoilValue(userOrganizationMembers);
	const userTimezone = userSettingsObj?.time_zone;
	const isPM = useRecoilValue(isProjectManagerAtom);
	const isMeetingHostOrPM = user.id === owningUserID || isPM;
	const isHostCard =
		typeof owningUserID === 'string' &&
		typeof userID === 'string' &&
		owningUserID === userID;

	const isInvited = distributionList.some(
		(user) =>
			(user.firstName === firstName && user.lastName === lastName) ||
			user.id === userID
	);

	const isUser = orgMembers.some((member) => member.id === userID);

	const highlightText = (text: string | undefined) => {
		return reactStringReplace(text, searchValue, (match, i) => (
			<span key={i} style={{ backgroundColor: 'yellow' }}>
				{match}
			</span>
		));
	};

	const emailOnly = !!email && !firstName && !lastName;
	const mainIdentifyingText = emailOnly
		? email
		: `${firstName} ${lastName}${isHostCard ? ' (you)' : ''}`;

	const showLinkedinIcon =
		showLinkedIn &&
		(emailOnly
			? true
			: (typeof firstName === 'string' && firstName?.length > 1) ||
			(typeof lastName === 'string' && lastName?.length > 1));

	const navigateToLinkedin = () => {
		let linkedinUrl = `https://www.linkedin.com/search/results/all/?keywords=${firstName}%20${lastName}`;
		if (emailOnly) {
			linkedinUrl = `https://www.linkedin.com/search/results/all/?keywords=${email}`;
		}
		const win = window.open(linkedinUrl, '_blank');
		win.focus();
	};

	return (
		<Grid.Col span={gridColSpan} p={0}>
			<Group className={classes.card} w={'100%'} position={'apart'} noWrap>
				<Group position={'left'} noWrap>
					<Avatar
						color={'primary'}
						variant={'filled'}
						radius={'md'}
						size={'md'}
					>
						{emailOnly ? (
							<FontAwesomeIcon icon={faAt} />
						) : (
							`${firstName?.substring(0, 1)}${lastName?.substring(
								0,
								1
							)}`.toUpperCase()
						)}
					</Avatar>
					<Stack
						w={'auto'}
						maw={'100%'}
						h={'100%'}
						spacing={1}
						justify={'center'}
					>
						<Tooltip label={mainIdentifyingText} position={'top'} withArrow>
							<Text className={classes.userName}>
								{searchValue.length
									? highlightText(mainIdentifyingText)
									: mainIdentifyingText}
								{isUser ? (
									<FontAwesomeIcon
										style={{ marginLeft: '4px' }}
										icon={faBadgeCheck}
										size={'xs'}
										color={
											theme.colors[theme.primaryColor][
											Number(theme.primaryShade)
											]
										}
									/>
								) : null}
							</Text>
						</Tooltip>
						{email && !emailOnly && (
							<Text w={'100%'} lineClamp={1} className={classes.email}>
								{searchValue.length ? highlightText(email) : email}
							</Text>
						)}
					</Stack>
				</Group>
				{!hideIcons && (
					<Group noWrap spacing={0}>
						{isMeetingHostOrPM && isAttendees ? (
							<ActionIcon
								variant='subtle'
								size={'md'}
								radius={'md'}
								component={'button'}
								onClick={() =>
									isInvited
										? handleRemoveUser(email)
										: inviteUser({
											email,
											firstName,
											lastName,
										})
								}
								className={classes.linkedInIcon}
								w={30}
							>
								<FontAwesomeIcon
									icon={faPaperPlane}
									fontSize={15}
									color={
										isInvited
											? theme.colors[theme.primaryColor][
											Number(theme.primaryShade)
											]
											: null
									}
								/>
							</ActionIcon>
						) : null}

						{showLinkedinIcon && (
							<ActionIcon
								variant='subtle'
								size={'md'}
								radius={'md'}
								component={'button'}
								onClick={navigateToLinkedin}
								className={classes.linkedInIcon}
								w={30}
							>
								<FontAwesomeIcon icon={faLinkedinIn} fontSize={15} />
							</ActionIcon>
						)}
						{isHostCard && !isAttendees ? (
							<Text className={classes.userName} color='#6B778C'>
								Owner
							</Text>
						) : !isAttendees ? (
							<Group noWrap spacing={2}>
								<Text w={'100%'} lineClamp={1} className={classes.email}>
									{expiresAt
										? `Expires ${convertEpochToDate(expiresAt, userTimezone)}`
										: ''}
								</Text>
								<ActionIcon
									variant='subtle'
									size={'sm'}
									radius={'md'}
									component={'button'}
									onClick={() => handleRemoveUser(email)}
									w={30}
									className={classes.deleteIcon}
								>
									<FontAwesomeIcon icon={faX} fontSize={15} />
								</ActionIcon>
							</Group>
						) : null}
					</Group>
				)}
			</Group>
		</Grid.Col>
	);
};

export default UserCard;
