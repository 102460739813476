import React, {
	MutableRefObject,
	createRef,
	useEffect,
	useRef,
	useState,
} from 'react';
import {
	createStyles,
	Group,
	Text,
	Stack,
	Timeline,
	Button,
	Box,
	Dialog,
	TextInput,
	ActionIcon,
	Textarea,
} from '@mantine/core';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
	currentMeetingChapters,
	mobileTimestamp,
	timelineBarScrollType,
	timestamp,
	videoPlayerCurrentTime,
} from '../../Atoms/meetingAtom';
import {
	defaultFailureNotificationProps,
	defaultSuccessNotificationProps,
	MILLISECONDS_PER_SECOND,
} from '../constants';
import {
	convertTimeToSeconds,
	formatSecondsToMinutesAndSeconds,
} from '../../_utils/time';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faPencil,
	faTrash,
	faPlus,
	faCheck,
	faHighlighter,
	faListCheck,
	faQuestion,
} from '@fortawesome/pro-solid-svg-icons';
import { Chapter } from '../../helpers/meetings';
import { IMeeting } from '../../interfaces/meeting';
import {
	createMeetingChapter,
	destroyMeetingChapter,
	updateMeetingChapter,
} from '../../api/api';
import { showNotification } from '@mantine/notifications';
import { logger } from '../../helpers/logger';
import { useForm } from '@mantine/form';
import { sortChaptersByTime } from '../../helpers/chapters';
import { useDisclosure, useFocusTrap } from '@mantine/hooks';
import { currentUser } from '../../Atoms/userAtoms';
import { isSupportUser } from '../../helpers/auth';
import TimestampInput from '../TimestampInput';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../helpers/notifications';
import _ from 'lodash';

const WEB_VIEW_TRANSLATE_Y = -8;
const MOBILE_VIEW_TRANSLATE_Y = -4;
const CHAR_LENGTH_BEFORE_SECOND_LINE_WRAP = 28;

const useStyles = createStyles((theme) => ({
	chapterBar: {
		cursor: 'pointer',
	},
	title: {
		color: theme.colors['primary-text'],
		fontWeight: 600,
		fontSize: 14,
		lineHeight: 1.55,
	},
	activeChapter: {
		cursor: 'pointer',
		backgroundColor: 'rgba(79, 70, 229, 0.1)',
		borderRadius: 8,
	},
}));

interface Props {
	meeting?: IMeeting;
	items: Chapter[];
	isWeb: boolean;
	title: string;
	bulletColor: string;
	activeBackgroundColor: string;
	editable?: boolean;
	asideRef?: MutableRefObject<any>;
	isMoments?: boolean;
	type?: 'chapters' | 'moments';
}

const CHAPTER_BULLET_SIZE = 24;
const MOMENT_BULLET_SIZE = 32;

export default function TimelineBars({
	items,
	meeting,
	isWeb,
	title,
	bulletColor,
	activeBackgroundColor,
	asideRef,
	editable = false,
	isMoments = false,
	type = 'chapters',
}: Props) {
	const { classes, theme } = useStyles();
	const user = useRecoilValue(currentUser);
	const setTimestamp = useSetRecoilState(mobileTimestamp);
	const setWebTimestamp = useSetRecoilState(timestamp);
	const setChapters = useSetRecoilState(currentMeetingChapters);
	const [activeChapterIndex, setActiveChapterIndex] = useState<number>(0);
	const [scrollType, setScrollType] = useRecoilState(timelineBarScrollType);
	const [editingChapter, setEditingChapter] = useState<Chapter | null>(null);
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [isSubmitting, setSubmitting] = useState<boolean>(false);
	const [opened, { open, close }] = useDisclosure(false);
	const [addingChapter, setAddingChapter] = useState(false);
	const isMeetingHostOrSupport =
		user.id === meeting.owningUserID || isSupportUser(user.roles);

	const [currentTime, setCurrentTime] = useRecoilState(videoPlayerCurrentTime);
	const timeStartInSeconds = editingChapter?.time / MILLISECONDS_PER_SECOND;
	const [editingChapterTimestamp, setEditingChapterTimestamp] = useState(
		formatSecondsToMinutesAndSeconds(timeStartInSeconds)
	);
	const [createChapterTimestamp, setCreateChapterTimestamp] = useState(
		formatSecondsToMinutesAndSeconds(currentTime)
	);
	const focusTrapRef = useFocusTrap();
	const [timestampExceedsVideoDuration, setTimestampExceedsVideoDuration] =
		useState(false);

	// Create a ref for each Topic
	const topicRefs = useRef([]);
	topicRefs.current = items.map((_, i) => topicRefs.current[i] ?? createRef());

	// Effect to calculate active chapter index and scroll to it
	useEffect(() => {
		let newActiveIndex = activeChapterIndex;
		// Find the index of the moment for the current time
		for (let i = 0; i < items.length; i++) {
			const { time } = items[i];
			if (currentTime < Math.floor(time / MILLISECONDS_PER_SECOND)) {
				newActiveIndex = i === 0 ? i : i - 1;
				break;
			} else if (
				currentTime >= Math.floor(time / MILLISECONDS_PER_SECOND) &&
				i === items.length - 1
			) {
				newActiveIndex = i;
				break;
			}
		}

		if (newActiveIndex !== activeChapterIndex) {
			setActiveChapterIndex(newActiveIndex);

			// Scroll the active moment card into view
			if (topicRefs.current[newActiveIndex] && scrollType === type && isWeb) {
				topicRefs.current[newActiveIndex].current.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
				});
				// topicRefs.current[newActiveIndex].current.scrollIntoView({
				// 	behavior: 'smooth',
				// 	block: 'nearest',
				// 	inline: 'start',
				// });
			}
		}
	}, [currentTime, items, activeChapterIndex]);

	const form = useForm({
		initialValues: {
			title: '',
			summary: '',
		},
		validate: {
			title: (value: string) => (value.length === 0 ? 'Field required.' : null),
			summary: (value: string) =>
				value.length === 0 ? 'Field required.' : null,
		},
	});

	useEffect(() => {
		form.setValues({
			title: editingChapter?.content || '',
			summary: editingChapter?.summary || '',
		});
	}, [editingChapter]);

	const handleAddChapter = () => {
		// open add chapter modal
		setAddingChapter(true);
		setCreateChapterTimestamp(formatSecondsToMinutesAndSeconds(currentTime));
		open();
	};

	const handleEditChapter = (chapter: Chapter) => {
		// open edit chapter modal
		setAddingChapter(false);
		setEditingChapter(chapter);
		// set form fields
		form.setValues({
			title: chapter.content,
			summary: chapter.summary,
		});
		setEditingChapterTimestamp(
			formatSecondsToMinutesAndSeconds(chapter.time / MILLISECONDS_PER_SECOND)
		);
		open();
	};

	const handleDeleteChapter = async (chapter: Chapter) => {
		try {
			// delete chapter
			await destroyMeetingChapter(
				meeting.id,
				chapter.id,
				meeting.organizationID
			);
			setChapters((prev) => prev.filter((c) => c.id !== chapter.id));
			showSuccessNotification({
				message: 'Meeting topic was successfully deleted!',
			});
			close();
		} catch (error) {
			logger('error', 'deleting chapter', error);
			showFailureNotification({
				message: 'Failed to delete meeting topic. Please try again.',
			});
		}
	};

	const addOrEditChapter = async (values) => {
		const { title, summary } = values;
		try {
			let successMessage = '';
			setSubmitting(true);
			if (addingChapter) {
				// add new chapter.
				const time =
					convertTimeToSeconds(createChapterTimestamp) *
					MILLISECONDS_PER_SECOND;
				const newChapter = await createMeetingChapter(
					{
						headline: title,
						gist: title,
						content: title,
						summary: summary,
						start: time,
						end: time,
					},
					meeting.id,
					meeting.organizationID
				);
				const {
					id,
					gist,
					start,
					summary: newSummary,
				} = _.get(newChapter, 'data.data', {});
				if (!id) {
					throw new Error(`Unexpected response format`);
				}
				setChapters(
					sortChaptersByTime([
						...items,
						{ id, time: start, content: gist, summary: newSummary },
					])
				);
				successMessage = 'Meeting topic was successfully added.';
			} else {
				// update existing chapter.
				const time =
					convertTimeToSeconds(editingChapterTimestamp) *
					MILLISECONDS_PER_SECOND;
				const updatedChapter = await updateMeetingChapter(
					{
						headline: title,
						gist: title,
						content: title,
						start: time,
						end: time,
						summary,
					},
					meeting.id,
					editingChapter.id,
					meeting.organizationID
				);
				const {
					id,
					gist,
					start,
					summary: newSummary,
				} = _.get(updatedChapter, 'data.data', {});
				if (!id) {
					throw new Error(`Unexpected response format`);
				}

				setChapters(
					sortChaptersByTime(
						items.map((chapter) =>
							chapter.id === id
								? { id, time: start, content: gist, summary: newSummary }
								: chapter
						)
					)
				);
				successMessage = 'Meeting topic was successfully updated.';
			}
			setSubmitting(false);
			showNotification({
				...defaultSuccessNotificationProps,
				title: 'Success!',
				message: successMessage,
			});
			form.reset();
			close();
		} catch (error) {
			setSubmitting(false);
			logger('error', 'adding or updating chapter', error);
			showNotification({
				...defaultFailureNotificationProps,
				title: 'Something went wrong',
				message: 'Failed to add/update topic - ' + error,
			});
		}
	};

	return (
		<>
			{isMeetingHostOrSupport && editable ? (
				<Dialog
					opened={opened}
					onClose={() => {
						setAddingChapter(false);
						setSubmitting(false);
						form.reset();
						close();
					}}
					withCloseButton
					size='lg'
					radius='md'
					transition={'slide-up'}
					position={{ bottom: 8, right: 16 }}
					p={16}
					w={asideRef?.current?.offsetWidth - 32}
					sx={{ zIndex: addingChapter ? 500 : 0 }}
				>
					<form onSubmit={form.onSubmit((values) => addOrEditChapter(values))}>
						<Stack ref={focusTrapRef}>
							<TimestampInput
								value={
									!addingChapter
										? editingChapterTimestamp
										: createChapterTimestamp
								}
								setValue={
									!addingChapter
										? setEditingChapterTimestamp
										: setCreateChapterTimestamp
								}
								duration={meeting?.videoMetadata?.duration}
								error={timestampExceedsVideoDuration}
								setError={setTimestampExceedsVideoDuration}
							/>
							<TextInput
								placeholder={'Topic Title'}
								{...form.getInputProps('title')}
								data-autofocus
							/>
							<Textarea
								placeholder={'Topic Summary'}
								{...form.getInputProps('summary')}
								minRows={6}
							/>
							<Group position={'right'}>
								<Button
									type={'submit'}
									disabled={isSubmitting || timestampExceedsVideoDuration}
								>
									{addingChapter ? 'Add' : 'Save'}
								</Button>
							</Group>
						</Stack>
					</form>
				</Dialog>
			) : null}

			<Stack
				mx={isWeb ? 0 : 'lg'}
			// mb={'lg'}
			// spacing={0}
			>
				{editable && isMeetingHostOrSupport ? (
					<Group position={'apart'} noWrap align={'center'}>
						<Text className={'sub-header'}>{title}</Text>
						<Group spacing={0} noWrap>
							<ActionIcon
								variant={'transparent'}
								onClick={() => setIsEditing((o) => !o)}
							>
								<FontAwesomeIcon icon={isEditing ? faCheck : faPencil} />
							</ActionIcon>
							<ActionIcon variant={'transparent'} onClick={handleAddChapter}>
								<FontAwesomeIcon icon={faPlus} />
							</ActionIcon>
						</Group>
					</Group>
				) : (
					<Text className={'sub-header'}>{title}</Text>
				)}

				<Timeline
					active={activeChapterIndex}
					bulletSize={isMoments ? MOMENT_BULLET_SIZE : CHAPTER_BULLET_SIZE}
					lineWidth={2}
					color={bulletColor}
				>
					{items.map((item, index) => {
						return (
							<Timeline.Item
								ref={topicRefs.current[index]}
								key={index}
								bullet={
									isMoments ? (
										<Box color={'primary-text'} p={'lg'}>
											<FontAwesomeIcon
												icon={
													item.type === 'highlight'
														? faHighlighter
														: item.type === 'action'
															? faListCheck
															: faQuestion
												}
												size='sm'
											/>
										</Box>
									) : (
										<Box color={'primary-text'}>{index + 1}</Box>
									)
								}
								onClick={() => {
									setWebTimestamp({
										time: Math.floor(item.time / MILLISECONDS_PER_SECOND),
									});
									setTimestamp({
										time: Math.floor(item.time / MILLISECONDS_PER_SECOND),
									});
									setCurrentTime(
										Math.floor(item.time / MILLISECONDS_PER_SECOND)
									);
									setScrollType(type);
								}}
							>
								{isWeb ? (
									<Stack
										spacing={0}
										p={'8px 16px'}
										className={
											index === activeChapterIndex
												? classes.activeChapter
												: classes.chapterBar
										}
										bg={
											index === activeChapterIndex ? activeBackgroundColor : ''
										}
										style={{ transform: 'translateY(-8px)' }}
									>
										{editable && isMeetingHostOrSupport && isEditing ? (
											<Group position={'apart'} noWrap>
												<Stack spacing={0}>
													<Text className={classes.title} lineClamp={2} p={2}>
														{item?.content || item?.value}
													</Text>
													{!isMoments ? (
														<Text size={12} color='secondary-text'>
															{item.summary ?? 'No description.'}
														</Text>
													) : null}
												</Stack>
												<Stack
													justify={'space-evenly'}
													style={{ transform: 'translateY(10px)' }}
													spacing={'sm'}
													h={'100%'}
												>
													<FontAwesomeIcon
														style={{
															color: theme.colors['secondary-text'][0],
															cursor: 'pointer',
														}}
														icon={faPencil}
														onClick={(e) => {
															e.stopPropagation();
															handleEditChapter(item);
														}}
														size={'sm'}
													/>
													<FontAwesomeIcon
														icon={faTrash}
														style={{
															color: theme.colors['secondary-text'][0],
															cursor: 'pointer',
														}}
														onClick={(e) => {
															e.stopPropagation();
															handleDeleteChapter(item);
														}}
														size={'sm'}
													/>
												</Stack>
											</Group>
										) : (
											<>
												<Text className={classes.title} lineClamp={2} p={2}>
													{item.content || item.value}
												</Text>
												{!isMoments ? (
													<Text size={12} color='secondary-text'>
														{item.summary ?? 'No description.'}
													</Text>
												) : null}
											</>
										)}
										<Text
											size='xs'
											fw={400}
											lh={'18px'}
											color={'primary.8'}
											mt={6}
										>
											{formatSecondsToMinutesAndSeconds(
												item.time / MILLISECONDS_PER_SECOND
											)}
										</Text>
									</Stack>
								) : (
									<>
										{isMoments ? (
											<Group
												position={'apart'}
												noWrap
												p={'8px 16px'}
												style={{
													transform: `translateY(${(item.content ?? item.value ?? '')?.length >
															CHAR_LENGTH_BEFORE_SECOND_LINE_WRAP
															? WEB_VIEW_TRANSLATE_Y
															: MOBILE_VIEW_TRANSLATE_Y
														}px)`,
												}}
												className={
													index === activeChapterIndex
														? classes.activeChapter
														: classes.chapterBar
												}
												bg={
													index === activeChapterIndex
														? activeBackgroundColor
														: ''
												}
											>
												<Text className={classes.title} lineClamp={2} p={2}>
													{item.content || item.value}
												</Text>
												{!isMoments ? (
													<Text size={12} color='secondary-text'>
														{item.summary ?? 'No description.'}
													</Text>
												) : null}
												<Text
													size='xs'
													fw={400}
													lh={'18px'}
													color={'primary-text'}
												>
													{formatSecondsToMinutesAndSeconds(
														(item.time || item.timeStartMS) /
														MILLISECONDS_PER_SECOND
													)}
												</Text>
											</Group>
										) : (
											<Stack
												spacing={'xs'}
												p={'8px 16px'}
												style={{
													transform: `translateY(${(item.content ?? item.value ?? '')?.length >
															CHAR_LENGTH_BEFORE_SECOND_LINE_WRAP
															? WEB_VIEW_TRANSLATE_Y
															: MOBILE_VIEW_TRANSLATE_Y
														}px)`,
												}}
												className={
													index === activeChapterIndex
														? classes.activeChapter
														: classes.chapterBar
												}
												bg={
													index === activeChapterIndex
														? activeBackgroundColor
														: ''
												}
											>
												<Text className={classes.title} lineClamp={2} p={2}>
													{item.content || item.value}
												</Text>
												{!isMoments ? (
													<Text size={12} color='secondary-text'>
														{item.summary ?? 'No description.'}
													</Text>
												) : null}
												<Text
													size='xs'
													fw={400}
													lh={'18px'}
													color={'primary-text'}
												>
													{formatSecondsToMinutesAndSeconds(
														(item.time || item.timeStartMS) /
														MILLISECONDS_PER_SECOND
													)}
												</Text>
											</Stack>
										)}
									</>
								)}
							</Timeline.Item>
						);
					})}
				</Timeline>
			</Stack>
		</>
	);
}
