import React, { useEffect, useState } from 'react';
import {
	AppShell,
	Navbar,
	Header,
	Burger,
	useMantineTheme,
	Group,
	Center,
	Badge,
	ScrollArea,
	Stack,
} from '@mantine/core';
import MainLinks from './mainLinks';

import { Logo } from './reelayLogo';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
	isAuthenticated,
	userAuthenticated as userAuthenticatedAtom,
} from '../Atoms/auth';
import { user as userAtom, userOrganizationMembers } from '../Atoms/userAtoms';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { AvatarHeaderWithMenu } from './AvatarHeaderWithMenu';
import { currentUser } from '../Atoms/userAtoms';
import CreateReelayModal from './create_reelay_modal';
import CreateReelayBotButton from './Buttons/create_reelayBot_button';
import { ServerError } from './ServerError';
import { ErrorBoundary } from 'react-error-boundary';
import { isAdmin } from '../helpers/auth';
import { NotificationsPopover } from './Popovers/NotificationsPopover';
import { navbarOpenedAtom } from '../Atoms/layout';
import MobileHeader from './mobileHeader';
import { useParams } from 'react-router-dom';
import * as API from '../api/api';
import { logger } from '../helpers/logger';
import { ReelayLoader } from './ReelayLoader';
import { useFlagsmith } from 'flagsmith/react';
import flagsmith from 'flagsmith';
import { calendarSyncingData } from '../Atoms/integrations';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../helpers/notifications';
import MeetingRecordingStack from './MeetingRecordingStack';
import UpcomingMeetingStack from './UpcomingMeetingStack';
import Gleap from 'gleap';
import useMediaQueries from '../customHooks/useMediaQueries';
import { viewingMeetingAtom } from '../Atoms/meetingAtom';

export default function Layout() {
	const theme = useMantineTheme();
	const navigate = useNavigate();
	const { meetingID } = useParams();
	const { identify } = useFlagsmith();
	const authenticated = useRecoilValue(isAuthenticated);
	const [navbarOpened, setNavbarOpened] = useRecoilState(navbarOpenedAtom);
	const setAuthStatus = useSetRecoilState(userAuthenticatedAtom);
	const [error, setError] = useState<string | null>();
	const [isLoading, setIsLoading] = useState(true);
	const user = useRecoilValue(currentUser);
	const setUserState = useSetRecoilState(userAtom);
	const setOrganizationMembers = useSetRecoilState(userOrganizationMembers);
	const calendarSyncing = useRecoilValue(calendarSyncingData);
	const [syncingCompleted, setSyncingCompleted] = useState(false);
	const [timerExpired, setTimerExpired] = useState(false);
	const atLeastOneCalendarSyncing =
		Object.keys(calendarSyncing).length === 0
			? false
			: Object.values(calendarSyncing).some((value) => value === true);
	const { isMobile, isTabletOrSmaller, widthOrHeightLessThanLG } =
		useMediaQueries();
	const viewingMeeting = useRecoilValue(viewingMeetingAtom);
	const navbarHidden = !navbarOpened && (isMobile || isTabletOrSmaller);
	const isViewingMeeting = window.location.href.includes('/meetings/');
	const isAuditPage = window.location.href.includes('/admin/edit/');
	const seeUploadMeetingButton =
		user.roles.includes('create-meeting') &&
		!user.roles.includes('support') &&
		!user.roles.includes('masteradmin');

	const seeAddBotButton =
		user.roles.includes('support') || user.roles.includes('masteradmin');

	async function logout() {
		setAuthStatus(false);
		try {
			await API.logout();
			localStorage.clear();
			Gleap.clearIdentity();
			showSuccessNotification({
				message: 'You were logged out!',
			});
			navigate('/login');
		} catch (err: unknown) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			setError(err.message);
			showFailureNotification({
				message:
					'Sorry, we encountered an error while logging you out. Please try again.',
			});
		}
		return;
	}

	const HOW_LONG_TO_SHOW_SYNCING_COMPLETE = 10000;
	useEffect(() => {
		let timer;
		const syncingComplete =
			Object.keys(calendarSyncing).length &&
			Object.values(calendarSyncing).every((value) => value === false);

		// start the timeout when all calendars are synced.
		if (syncingComplete) {
			setSyncingCompleted(true);
			timer = setTimeout(() => {
				setSyncingCompleted(true);
				setTimerExpired(true);
			}, HOW_LONG_TO_SHOW_SYNCING_COMPLETE);
		} else {
			clearTimeout(timer);
			setSyncingCompleted(false);
			setTimerExpired(false);
		}

		return () => {
			clearTimeout(timer);
		};
		// if a new calendar begins syncing, reset the timeout
		// if the timeout completes, set the state boolean to false and remove badge.
	}, [calendarSyncing]);

	useEffect(() => {
		const handleGetCurrentUser = async () => {
			try {
				const user = await API.getCurrentUser();
				setUserState(user);

				// set flagsmith identity
				flagsmith.identify(user.id, {
					id: user.id,
					email: user.email,
					first_name: user.firstName,
					last_name: user.lastName,
					role: JSON.stringify(user.roles),
					organizationID: user.currentOrganizationID,
				});

				Gleap.identify(user.id, {
					email: user.email,
					name: `${user.firstName} ${user.lastName}`,
					companyId: user.currentOrganizationID,
					customData: {
						role: JSON.stringify(user.roles),
					},
				});

				const { currentOrganizationID } = user;

				const usersResponse = await API.getUsersByOrg(currentOrganizationID);
				const users = usersResponse;
				setOrganizationMembers(users);
			} catch (err) {
				logger('error', 'Error getting current user', err);
			} finally {
				setIsLoading(false);
			}
		};
		if (authenticated) {
			handleGetCurrentUser();
		} else {
			setIsLoading(false);
		}
	}, []);

	// @TODO: This should clear the userToken that comes back from the MagicLink Login and then ensures that the user is directed back to the Login Page.

	return (
		<AppShell
			// navbarOffsetBreakpoint='sm'
			asideOffsetBreakpoint='sm'
			padding={isMobile ? 0 : 'md'}
			style={{
				backgroundColor: theme.colors['background-gray'][0],
				height: '100vh',
				maxHeight: '100vh',
				boxSizing: 'border-box',
			}}
			styles={{
				body: {
					height: '100%',
				},
				main: {
					paddingBottom: isViewingMeeting ? 0 : 'md',
				},
			}}
			navbar={
				isAuditPage || isMobile ? null : (
					<>
						<Navbar p='md' width={{ xl: 300, base: 300 }}>
							{!authenticated && (
								<a
									href={'https://www.reelay.com/request-a-demo'}
									target='_blank'
								>
									<div
										style={{
											position: 'absolute',
											top: 0,
											left: 0,
											width: '100%',
											height: '100%',
											backgroundColor: 'rgba(0, 0, 0, 0.07)',
											zIndex: 9999,
											backdropFilter: 'blur(2.5px)',
										}}
									/>
								</a>
							)}
							<Navbar.Section>
								<MainLinks />
							</Navbar.Section>
							{!authenticated ? null : (
								<Navbar.Section
									grow
									id='happening-now-upcoming-navbar-section'
									style={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'end',
										flex: 2,
										width: 'calc(100% + 10px)',
										alignItems: 'flex-end',
										overflow: 'hidden',
										gap: theme.spacing.xs,
									}}
								>
									<MeetingRecordingStack />
									<UpcomingMeetingStack />
								</Navbar.Section>
							)}
						</Navbar>
					</>
				)
			}
			header={
				!authenticated ? (
					<Header
						height={70}
						style={{ boxShadow: '0 3px 12px 0 rgba(30, 30, 30, 0.08)' }}
					>
						<Logo />
					</Header>
				) : isMobile ? (
					<MobileHeader />
				) : (
					<Header height={70} p='md' style={{ border: '1px solid #E1E1E1' }}>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								height: '100%',
								justifyContent: 'space-between',
							}}
						>
							{isTabletOrSmaller ? (
								<Burger
									opened={navbarOpened}
									onClick={() => setNavbarOpened((o) => !o)}
									size='sm'
									color={theme.colors.gray[6]}
									mr='xl'
								/>
							) : null}
							<Logo addPoweredByCaption={true} />
							<Group noWrap>
								{atLeastOneCalendarSyncing ? (
									<Badge variant='dot'>SYNCING CALENDAR</Badge>
								) : syncingCompleted && !timerExpired ? (
									<Badge variant='dot' color='green'>
										CALENDAR SYNCED
									</Badge>
								) : null}
								{seeUploadMeetingButton && <CreateReelayModal />}
								<NotificationsPopover />
								<AvatarHeaderWithMenu logout={logout} />
							</Group>
						</div>
					</Header>
				)
			}
		>
			<ErrorBoundary
				FallbackComponent={ServerError}
				onReset={() =>
					navigate(isAdmin(user.roles) ? '/admin/meetings' : '/meetings')
				}
			>
				{isLoading ? (
					<Center h={'100%'}>
						<ReelayLoader />
					</Center>
				) : (
					<Outlet />
				)}
			</ErrorBoundary>
		</AppShell>
	);
}
