import React, { useEffect } from 'react';
import { createStyles, Grid, Text } from '@mantine/core';
import UserCard from './UserCard';
import { InvitedUser } from '../../interfaces/user';
import { useListState } from '@mantine/hooks';

interface Props {
	users: (InvitedUser | [string | null, string | null, string | null])[];
	gridColSpan: number;
	searchValue?: string;
	emptyText: string;
	handleRemoveUser?: (email: string) => void;
	inviteUser?: ({
		email,
		firstName,
		lastName,
	}: {
		email: string;
		firstName: string;
		lastName: string;
	}) => void;
	isAttendees?: boolean;
	showLinkedIn?: boolean;
	owningUserID?: string | null;
	hideIcons?: boolean;
}

const useStyles = createStyles(() => ({
	container: {
		maxHeight: '40%',
	},
}));

const UserList = ({
	users,
	gridColSpan,
	emptyText,
	handleRemoveUser,
	inviteUser,
	searchValue = '',
	isAttendees = false,
	showLinkedIn = true,
	owningUserID = null,
	hideIcons = false,
}: Props) => {
	const { classes } = useStyles();
	const [list, handlers] = useListState([]);

	useEffect(() => {
		// users with actual data, this prevents no empty user cards.
		let usersWithData = [...users];
		// filter by search value
		if (typeof searchValue === 'string' && searchValue.length) {
			usersWithData = usersWithData.filter((user) => {
				if (Array.isArray(user)) {
					const [email, firstName, lastName] = user;
					return (
						email?.toLowerCase().includes(searchValue.toLowerCase()) ||
						firstName?.toLowerCase().includes(searchValue.toLowerCase()) ||
						lastName?.toLowerCase().includes(searchValue.toLowerCase())
					);
				} else if (user && typeof user === 'object') {
					const { email = '', firstName = '', lastName = '' } = user;
					return (
						email?.toLowerCase()?.includes(searchValue.toLowerCase()) ||
						firstName?.toLowerCase()?.includes(searchValue.toLowerCase()) ||
						lastName?.toLowerCase()?.includes(searchValue.toLowerCase())
					);
				}
			});
		}
		handlers.setState(usersWithData);
	}, [searchValue, users]);

	return (
		<>
			{list.length ? (
				<Grid className={classes.container}>
					{list.map((user) => {
						if (Array.isArray(user)) {
							const [email = '', firstName = '', lastName = ''] = user;
							return (
								<UserCard
									key={`${firstName} ${lastName} ${email}`}
									gridColSpan={gridColSpan}
									email={email}
									firstName={firstName}
									lastName={lastName}
									searchValue={searchValue}
									handleRemoveUser={handleRemoveUser}
									isAttendees={isAttendees}
									showLinkedIn={showLinkedIn}
									owningUserID={owningUserID}
									inviteUser={inviteUser}
									hideIcons={hideIcons}
								/>
							);
						} else if (user && typeof user === 'object') {
							const {
								email = '',
								firstName = '',
								lastName = '',
								id = '',
								expiresAt = 0,
							} = user;
							return (
								<UserCard
									key={user?.id}
									gridColSpan={gridColSpan}
									email={email}
									firstName={firstName}
									lastName={lastName}
									userID={id}
									expiresAt={expiresAt}
									searchValue={searchValue}
									handleRemoveUser={handleRemoveUser}
									isAttendees={isAttendees}
									showLinkedIn={showLinkedIn}
									owningUserID={owningUserID}
									inviteUser={inviteUser}
									hideIcons={hideIcons}
								/>
							);
						}
						return null;
					})}
				</Grid>
			) : (
				<Text size={14} align={'left'}>
					{emptyText}
				</Text>
			)}
		</>
	);
};

export default UserList;
