import { Paper, Switch, Text } from '@mantine/core';
import { styles } from '../../../../../../settings/tabs/styles';
import { OrganizationSettings } from '../../../../../../../Atoms/settings';
import { useState } from 'react';
import { logger } from '../../../../../../../helpers/logger';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../../../../../helpers/notifications';
import { saveOrganizationSettings } from '../../../../../../../api/settings';
import { useParams } from 'react-router';
import { getEnvForGleap } from '../../../../../../../_utils/trackers';

interface Props {
	organizationSettings: OrganizationSettings;
	setOrganizationSettings: React.Dispatch<
		React.SetStateAction<OrganizationSettings>
	>;
}

export default function SharingDefault({
	organizationSettings,
	setOrganizationSettings,
}: Props) {
	const { classes } = styles();
	const { organizationID } = useParams();
	const { sharing_default: sharingDefault } = organizationSettings;
	const [checked, setChecked] = useState<boolean>(sharingDefault);
	if (checked !== sharingDefault) {
		setChecked(sharingDefault);
	}

	const handleDefaultSharingChange = async (value: boolean) => {
		try {
			setChecked(value);
			const payload = [
				{
					key: 'sharing_default',
					value,
				},
			];
			const res = await saveOrganizationSettings(payload, organizationID);
			const details = res[0];
			setOrganizationSettings((prev) => ({
				...prev,
				[details?.key]: details?.value,
			}));
			showSuccessNotification({
				message: 'You have successfully changed the default sharing setting.',
			});
		} catch (error) {
			logger('error', 'error changing default sharing', error);
			setChecked(!value);
			showFailureNotification({
				message:
					'Sorry, there was an error changing the default sharing setting. Please try again.',
			});
		}
	};

	return (
		<Paper
			className={classes.card}
			shadow='xs'
			p='md'
			radius={'md'}
			id={`VIS_ctf9nfdtmsxfgg2vmcd0_${getEnvForGleap()}`}
		>
			<Switch
				labelPosition='left'
				label="Organization Users' Default Sharing Preference"
				description={
					<Text
						style={{
							cursor: 'default',
						}}
					>
						Enable this setting to have new meetings created with sharing
						preference set to{' '}
						<Text span fs='italic'>
							Anyone with Link
						</Text>
						. Otherwise the default will be{' '}
						<Text span fs='italic'>
							Invite only
						</Text>
						.
					</Text>
				}
				size='sm'
				w={'100%'}
				width={'100%'}
				mb={'lg'}
				checked={checked}
				onChange={(e) => handleDefaultSharingChange(e.currentTarget.checked)}
				styles={{
					body: {
						width: '100%',
						justifyContent: 'space-between',
						flex: 1,
					},
				}}
			/>
		</Paper>
	);
}
