import { atom, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { User } from './userAtoms';
import { CollectionTableProps } from '../pages/collections/components/CollectionTable';
import { IMeeting } from '../interfaces/meeting';

const { persistAtom } = recoilPersist();

export const defaultCollectionTableProps: CollectionTableProps = {
	id: '',
	name: '',
	owner: 'Unknown',
	lastModified: '',
};

export interface Collection {
	createdAt: string;
	description: string | null;
	id: string;
	name: string;
	organizationID: string;
	owningUserID: string;
	parentID: string | null;
	updatedAt: string;
	owner?: User;
	content?: Content[];
	users?: User[];
}

export interface Content {
	collectionID: string;
	createdAt: string;
	id: string;
	meetingID: string;
	organizationID: string;
	updatedAt: string;
}

export interface CollectionUser {
	collectionID: string;
	createdAt: string;
	id: string;
	organizationID: string;
	updatedAt: string;
	user: User;
	userID: string;
}

export const defaultCollection: Collection = {
	createdAt: '',
	description: '',
	id: '',
	name: '',
	organizationID: '',
	owningUserID: '',
	parentID: '',
	updatedAt: '',
	content: [],
	users: [],
};

export const collectionsAtom = atom<Collection[]>({
	key: 'collectionsAtom',
	default: [],
});

export enum SpecialCollections {
	Archived = 'Archived',
}

export const archiveCollectionAtom = selector({
	key: 'archiveCollectionAtom',
	get: ({ get }) => {
		const collections = get(collectionsAtom);
		const archiveCollection = collections.find(
			(collection) => collection.name === SpecialCollections.Archived
		);
		return archiveCollection;
	},
});

export const addMeetingToCollectionModalOpenedAtom = atom<boolean>({
	key: 'addMeetingToCollectionModalOpenedAtom',
	default: false,
});

export const meetingBeingAddedToCollection = atom<IMeeting | null>({
	key: 'meetingBeingAddedToCollection',
	default: null,
});

export const meetingsBeingAddedToCollection = atom<IMeeting[]>({
	key: 'meetingsBeingAddedToCollection',
	default: [],
});

export const renameModalOpenedAtom = atom<boolean>({
	key: 'renameModalOpenedAtom',
	default: false,
});

export const editingCollectionAtom = atom<CollectionTableProps>({
	key: 'editingCollectionAtom',
	default: defaultCollectionTableProps,
});

export const currentCollection = atom<Collection>({
	key: 'currentCollection',
	default: defaultCollection,
});

export const bulkDeletingCollectionMeetings = atom<boolean>({
	key: 'bulkDeletingCollectionMeetings',
	default: false,
});

export const bulkDeletingCollectionMeetingsSelection = atom<string[]>({
	key: 'bulkDeletingCollectionMeetingsSelection',
	default: [],
});

export const archiveBulkButtonLoadingAtom = atom<boolean>({
	key: 'archiveBulkButtonLoadingAtom',
	default: false,
});

export const meetingCollectionIDsUpdatedToggleAtom = atom<boolean>({
	key: 'meetingCollectionIDsUpdatedToggleAtom',
	default: false,
});