import axiosInstance from '../axios/axios';
import { logger } from '../helpers/logger';

export const transferMeetingOwner = (meetingID: string, userID: string) => {
	return axiosInstance
		.post(`v1/meeting/${meetingID}/transfer-owner`, {
			userID,
		})
		.then((res) => {
			console.log('transferMeetingOwner res', res);
			logger('info', 'Response received from getMeetingNotifications', res);
			return res?.data;
		})
		.catch((err) => {
			logger('error', 'Error with transferMeetingOwner', err);
			return err;
		});
};

export const generateMeetingTranscript = (meetingID: string) => {
	return axiosInstance
		.post(`v1/meeting/${meetingID}/generate-transcript`)
		.then((res) => {
			console.log('generateMeetingTranscript res', res);
			logger('info', 'Response received from getMeetingNotifications', res);
			return res?.data;
		})
		.catch((err) => {
			logger('error', 'Error with generateMeetingTranscript', err);
			return err;
		});
};
