import {
	faFileLines,
	faPaperclip,
	faPlus,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	ActionIcon,
	Button,
	FileButton,
	Group,
	Menu,
	Modal,
	ScrollArea,
	Stack,
	Text,
	Tooltip,
	UnstyledButton,
	createStyles,
	useMantineTheme,
} from '@mantine/core';
import { useRecoilState, useRecoilValue } from 'recoil';
import { meetingAttachments } from '../../../../../Atoms/meetingAtom';
import { IAttachment, currentUser } from '../../../../../Atoms/userAtoms';
import {
	addAttachmentsToMeeting,
	removeAttachmentsFromMeeting,
	sendAnalyticEvent,
} from '../../../../../api/api';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../../../helpers/notifications';
import { logger } from '../../../../../helpers/logger';
import { IMeeting } from '../../../../../interfaces/meeting';
import { faWandMagicSparkles } from '@fortawesome/pro-light-svg-icons';
import { useRef, useState } from 'react';
import { isMeetingOwnerOrProjectManager } from '../../../../../helpers/auth';
import {
	downloadAttachment,
	getAttachmentIcon,
} from '../../../../../helpers/attachments';
import useMediaQueries from '../../../../../customHooks/useMediaQueries';
import {
	faEllipsisVertical,
	faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import {
	removeHtmlTags,
	replaceApostropheHTMLEntityWithSingleQuote,
} from '../../../../../_utils/handy-functions';
import { useDisclosure } from '@mantine/hooks';

const useStyles = createStyles((theme) => ({
	magicWand: {
		color: theme.colors['primary-cta'][0],
		position: 'absolute',
		bottom: 15,
		right: 30,
		cursor: 'pointer',
	},
	trashIcon: {
		cursor: 'pointer',
		height: 16,
		width: 16,
		marginTop: 3,
	},
}));

interface Props {
	meeting: IMeeting;
}

export default function SummaryTabContent({ meeting }: Props) {
	const { classes, theme } = useStyles();
	const [attachments, setAttachments] = useRecoilState(meetingAttachments);
	const user = useRecoilValue(currentUser);
	const [opened, { open, close }] = useDisclosure(false);
	const { summary, organizationID, id, owningUserID } = meeting;
	const isOwnerOrPM = isMeetingOwnerOrProjectManager(
		owningUserID,
		user.id,
		user.roles
	);
	const uploadAttachmentButtonRef = useRef(null);

	const attachmentsExcludingMinutesAndTranscript = attachments.filter(
		(attachment) =>
			attachment.type !== 'minutes' && attachment.type !== 'transcript'
	);

	const removeAttachment = async (attachmentID: string) => {
		try {
			const attachmentResponse = await removeAttachmentsFromMeeting(
				[attachmentID],
				meeting.id,
				meeting.organizationID
			);
			const removedAttachmentID =
				attachmentResponse?.data?.data?.removedAttachmentIDs[0];
			setAttachments(
				attachments.filter(
					(attachment: IAttachment) => attachment.id !== removedAttachmentID
				)
			);
			showSuccessNotification({
				message: 'Attachment was successfully removed! Great work!',
			});
		} catch (err) {
			console.log('err', err);
			logger('error', 'error removing attachment', err);
			showFailureNotification({
				message:
					'Apologies, we encountered an issue while trying to remove the attachment. Please attempt this action again or get in touch with our support team for assistance.',
			});
		}
	};

	const uploadAttachment = async (files: File[]) => {
		const file = files[0];
		try {
			// setUploadingAttachment(true);
			const attachmentData = new FormData();
			await attachmentData.append(`attachment${0}`, file, file.name);
			const attachmentResponse = await addAttachmentsToMeeting({
				data: attachmentData,
				params: {
					meetingID: meeting.id,
					organizationID: meeting.organizationID,
				},
				headers: { 'Content-Type': undefined },
			});
			setAttachments([...attachments, attachmentResponse.data.data[0]]);
			showSuccessNotification({
				message: 'Attachment was successfully uploaded! Great work!',
			});
		} catch (err) {
			logger('error', 'error uploading attachment', err);
			if (err?.response?.status === 413) {
				showFailureNotification({
					message:
						'Sorry, but the file you are trying to upload is too large. Please select a file that is smaller and try again.',
				});
				return;
			}
			showFailureNotification({
				message:
					'We apologize, but there was an error while uploading the attachment. Please try the upload again or contact our support team if you continue to experience issues.',
			});
		} finally {
			// setUploadingAttachment(false);
		}
	};

	return (
		<>
			<Stack
				h={'100%'}
				w={'100%'}
				style={{
					justifyContent: 'flex-start',
				}}
			>
				<ScrollArea
					p={'md'}
					pb={0}
					lh={'25px'}
					type='always'
					offsetScrollbars
					scrollbarSize={10}
					mih={100}
					styles={{
						root: {
							flex: 2,
						},
						viewport: {
							overflowX: 'hidden',
							width: '100%',
							maxWidth: '100%',
							fontSize: 14,
							fontWeight: 400,
							lineHeight: '25px',
						},
					}}
				>
					{removeHtmlTags(summary)}
				</ScrollArea>

				<Group
					position='apart'
					align='center'
					style={{
						borderBottom: `3px solid #8C919B`,
					}}
					noWrap
					spacing={'xs'}
				>
					<Group spacing={'xs'} noWrap>
						<FontAwesomeIcon
							icon={faPaperclip}
							color={theme.colors['primary-cta'][0]}
						/>
						<Text size={14} fw={700} color='primary-cta'>
							Attachments ({attachmentsExcludingMinutesAndTranscript.length})
						</Text>
					</Group>
					<Group
						spacing={'xs'}
						position='left'
						style={{
							flex: 2,
						}}
					>
						{attachmentsExcludingMinutesAndTranscript.length ? (
							attachmentsExcludingMinutesAndTranscript.map((attachment) => (
								<Tooltip
									key={attachment.id}
									label={replaceApostropheHTMLEntityWithSingleQuote(
										attachment.fileName
									)}
									position='top'
								>
									<FontAwesomeIcon
										icon={getAttachmentIcon(attachment.fileName)}
										style={{
											cursor: 'pointer',
										}}
										color={theme.colors['primary-cta'][0]}
										onClick={() =>
											downloadAttachment(attachment, organizationID)
										}
										size='lg'
									/>
								</Tooltip>
							))
						) : (
							<Text lh={'25px'} fz={12}>
								There were no attachments for this meeting.
							</Text>
						)}
					</Group>
					<FileButton
						onChange={uploadAttachment}
						accept='image/png,image/jpeg,application/pdf,.doc,.docx,video/*'
						multiple
					>
						{(props) => (
							<Button
								ref={uploadAttachmentButtonRef}
								{...props}
								variant='subtle'
								fz={14}
								fw={400}
								style={{
									display: 'none',
								}}
								styles={{
									root: {
										'&:hover': {
											backgroundColor: theme.colors['background-gray-2'][0],
										},
										paddingLeft: 0,
										paddingRight: 0,
									},
								}}
							>
								Add Attachment
							</Button>
						)}
					</FileButton>
					{isOwnerOrPM ? (
						<Menu
							shadow='md'
							width={275}
							zIndex={500000}
							transitionProps={{ transition: 'pop' }}
							position='bottom-end'
							withinPortal
						>
							<Menu.Target>
								<ActionIcon onClick={(e) => e.stopPropagation()} pt={0}>
									<FontAwesomeIcon
										icon={faEllipsisVertical}
										style={{
											color: theme.colors['secondary-text'][0],
											cursor: 'pointer',
										}}
									/>
								</ActionIcon>
							</Menu.Target>
							<Menu.Dropdown>
								<Menu.Item
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										uploadAttachmentButtonRef?.current?.click();
									}}
									icon={
										<FontAwesomeIcon
											style={{
												color: theme.colors['secondary-text'][0],
												cursor: 'pointer',
											}}
											icon={faPlus}
											size={'sm'}
										/>
									}
								>
									Add Attachment
								</Menu.Item>
								<Menu.Item
									onClick={(e) => {
										e.stopPropagation();
										open();
									}}
									icon={
										<FontAwesomeIcon
											style={{
												color: theme.colors['secondary-text'][0],
												cursor: 'pointer',
											}}
											icon={faTrash}
											size={'sm'}
										/>
									}
								>
									Delete Attachments
								</Menu.Item>
							</Menu.Dropdown>
						</Menu>
					) : null}
				</Group>
			</Stack>
			<Modal
				opened={opened}
				onClose={close}
				title='Delete Attachments'
				scrollAreaComponent={ScrollArea.Autosize}
				radius={'lg'}
				styles={(theme) => ({
					header: {
						borderRadius: theme.spacing.lg,
					},
				})}
				p={'md'}
			>
				<Stack>
					{attachmentsExcludingMinutesAndTranscript.map((attachment) => (
						<Group position='apart' w={'100%'} noWrap key={attachment.id}>
							<Group
								spacing={'xs'}
								noWrap
								w={'100%'}
								onClick={() => downloadAttachment(attachment, organizationID)}
								style={{
									cursor: 'pointer',
								}}
							>
								<FontAwesomeIcon
									icon={getAttachmentIcon(attachment.fileName)}
									color={theme.fn.primaryColor()}
								/>
								<Text
									size={12}
									truncate
									fw={500}
									color={theme.fn.primaryColor()}
								>
									{attachment.fileName}
								</Text>
							</Group>
							{isOwnerOrPM ? (
								<FontAwesomeIcon
									icon={faTrash}
									className={classes.trashIcon}
									onClick={() => removeAttachment(attachment.id)}
								/>
							) : null}
						</Group>
					))}
				</Stack>
			</Modal>
		</>
	);
}
