import axiosInstance from '../axios/axios';
import { logger } from '../helpers/logger';

export const assignSupporterToOrganization = (
	userID: string,
	organizationID: string
) => {
	return axiosInstance
		.post(
			`v1/organization/${organizationID}/assign-supporter-to-organization`,
			{ userID }
		)
		.then((res) => {
			logger('info', 'Response received from getOrganizationSettings', res);
			return res.data.data;
		})
		.catch((err) => {
			logger('error', 'Error getting user settings', err);
			return err;
		});
};

export const unassignSupporterFromOrganization = (
	userID: string,
	organizationID: string
) => {
	return axiosInstance
		.post(
			`v1/organization/${organizationID}/unassign-supporter-from-organization`,
			{ userID }
		)
		.then((res) => {
			logger('info', 'Response received from getOrganizationSettings', res);
			return res.data.data;
		})
		.catch((err) => {
			logger('error', 'Error getting user settings', err);
			throw new Error(err);
		});
};
