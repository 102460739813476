import React, { useEffect, useState } from 'react';
import {
	Card,
	Text,
	Button,
	createStyles,
	Title,
	Badge,
	Stack,
	Image,
	Group,
	ActionIcon,
	Modal,
	Radio,
} from '@mantine/core';
import {
	buildGoogleCalendarOAuthUrl,
	buildMicrosoftOutlookOAuthUrl,
	removeCalendar,
	updateCalendar,
} from '../../api/api';
import { useRecoilState, useRecoilValue } from 'recoil';
import { currentUser } from '../../Atoms/userAtoms';
import { calendarStatusData, integrations } from '../../Atoms/integrations';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/pro-solid-svg-icons';
import { faCalendarDays, faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import { useDisclosure } from '@mantine/hooks';
import {
	CalendarIntegration,
	generateSlackLink,
} from '../../helpers/integrations';
import { showSuccessNotification } from '../../helpers/notifications';
import {
	IntegrationType,
	calendarIntegrationList,
	communicationsIntegrationList,
} from '../constants';
import { set } from 'lodash';

const useStyles = createStyles((theme) => ({
	card: {
		width: '275px',
	},
	cardButton: {
		width: '275px',
		transition: 'transform 0.3s ease',
		cursor: 'pointer',
		'&:hover': {
			transform: 'translateY(-5px)',
		},
	},
}));

interface Props {
	data: any;
	image: string;
	title: string;
	description: string;
	enabled: boolean;
	noButton?: boolean;
	type?: IntegrationType;
}

export default function IntegrationCard({
	data,
	image,
	title,
	description,
	enabled,
	noButton,
	type = IntegrationType.Integration,
}: Props) {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const { classes } = useStyles();
	const user = useRecoilValue(currentUser);
	const [existingIntegrations, setExistingIntegrations] =
		useRecoilState(integrations);
	const [opened, { open, close }] = useDisclosure(false);
	const calendarStatus = useRecoilValue(calendarStatusData);
	const [buttonLoading, setButtonLoading] = useState(false);
	const decideSettings = searchParams.get('decideSettings');
	const calendarID = searchParams.get('calendarID');
	const integrationFound = existingIntegrations.find(
		(integration: CalendarIntegration) =>
			integration?.platform === data.platform
	);
	const [saveButtonLoading, setSaveButtonLoading] = useState(false);

	const [settingsValue, setSettingsValue] = useState<string>(
		String(integrationFound?.autoRecord)
	);

	useEffect(() => {
		if (
			decideSettings &&
			integrationFound &&
			calendarID === integrationFound?.recallData?.id
		) {
			openIntegrationSettingsModal();
		}
	}, []);

	const openIntegrationSettingsModal = () => {
		open();
	};

	const handleIntegration = async (platform: string) => {
		try {
			setButtonLoading(true);
			// connects or disconnects from integration.
			if (!integrationFound) {
				localStorage.setItem('congratulate_onboarding_calendar_sync', 'true');
				// connect
				if (platform === 'google_calendar') {
					const res = await buildGoogleCalendarOAuthUrl(
						user.currentOrganizationID
					);
					const connectionURL = res?.data?.data?.connectionURL;
					window.location.href = connectionURL;
				} else if (platform === 'microsoft_outlook') {
					const res = await buildMicrosoftOutlookOAuthUrl(
						user.currentOrganizationID
					);
					const connectionURL = res?.data?.data?.connectionURL;
					window.location.href = connectionURL;
				} else if (platform === 'slack') {
					window.open(generateSlackLink(user.currentOrganizationID), '_blank');
				} else if (platform === 'zoom') {
					window.open(
						window.location.protocol + '//' + window.location.host + '/zoom',
						'_blank'
					);
				}
			} else {
				// disconnect
				const removedCalendar = await removeCalendar(
					integrationFound.id,
					user.currentOrganizationID
				);
				if (removedCalendar?.data?.data?.status === 'success') {
					setExistingIntegrations(
						existingIntegrations.filter(
							(integration: any) => integration?.id !== integrationFound?.id
						)
					);
				}
			}
		} catch (error) {
			console.error(error);
		} finally {
			setButtonLoading(false);
		}
	};

	const updateCalendarIntegrationSettings = async () => {
		try {
			setSaveButtonLoading(true);
			const autoRecord = +settingsValue;
			const isDifferentValue = integrationFound.autoRecord !== autoRecord;

			if (!isDifferentValue) {
				close();
				return;
			}

			const res = await updateCalendar(
				integrationFound.id,
				{ autoRecord },
				user.currentOrganizationID
			);
			const updatedCalendarIntegration = res?.data?.data;
			setExistingIntegrations((prev) =>
				prev.map((integration: any) =>
					integration.id === updatedCalendarIntegration.id
						? updatedCalendarIntegration
						: integration
				)
			);
			close();
			const congratulate_onboarding_calendar_sync = localStorage.getItem(
				'congratulate_onboarding_calendar_sync'
			);
			if (congratulate_onboarding_calendar_sync === 'true') {
				showSuccessNotification({
					title: 'Congratulations!',
					message:
						'You have successfully integrated your calendar with Reelay. You can now start scheduling meetings with ease and a Reelay assistant will join your meetings automatically.',
				});
				localStorage.removeItem('congratulate_onboarding_calendar_sync');
			}
			navigate('.', { replace: true }); // Replace the current URL with a new one without search parameters
		} catch (error) {
			console.error(error);
		} finally {
			setSaveButtonLoading(false);
		}
	};

	return (
		<>
			<Modal
				opened={opened}
				onClose={close}
				closeOnEscape={false}
				withCloseButton={false}
				styles={{
					title: {
						width: '100%',
					},
				}}
				title={
					<Group position={'apart'} noWrap w={'100%'}>
						<Group>
							<FontAwesomeIcon icon={faCalendarDays} />
							<Text>Auto-record calendar events</Text>
						</Group>
						<FontAwesomeIcon icon={faCircleInfo} />
					</Group>
				}
				radius={'lg'}
			>
				<Stack>
					<Radio.Group
						label='Select your preference below'
						withAsterisk
						value={settingsValue}
						onChange={setSettingsValue}
					>
						<Group mt='xs'>
							<Radio
								value='1'
								label="My Meetings (only the virtual meetings I've scheduled)"
							/>
							<Radio
								value='3'
								label="My Meetings + Team Meetings (other company virtual meetings I've been invited to)"
							/>
							<Radio
								value='7'
								label='All Meetings (every virtual meeting on my calendar)'
							/>
						</Group>
					</Radio.Group>
					<Group position={'right'}>
						<Button
							loading={saveButtonLoading}
							onClick={updateCalendarIntegrationSettings}
						>
							Save
						</Button>
					</Group>
				</Stack>
			</Modal>
			<Card
				radius={'md'}
				m='md'
				shadow='sm'
				p='md'
				withBorder
				className={noButton ? classes.cardButton : classes.card}
				onClick={(e) => {
					if (noButton) {
						e.stopPropagation();
						localStorage.setItem(
							'congratulate_onboarding_calendar_sync',
							'true'
						);
						handleIntegration(data.platform);
					}
				}}
			>
				<Group position={'apart'} align={'start'}>
					<Image maw={50} radius='md' src={data.image} alt='Random image' />
					<Group noWrap spacing={'xs'}>
						{integrationFound?.status === 'connected' ? (
							<Badge color='lime'>
								{type === IntegrationType.Integration
									? 'Connected'
									: 'Installed'}
							</Badge>
						) : integrationFound?.status === 'connecting' ? (
							<Badge color='lime'>
								{' '}
								{type === IntegrationType.Integration
									? 'Connecting'
									: 'Installing'}
							</Badge>
						) : null}
						{integrationFound ? (
							<ActionIcon onClick={() => openIntegrationSettingsModal()}>
								<FontAwesomeIcon icon={faCog} />
							</ActionIcon>
						) : null}
					</Group>
				</Group>
				<br />
				<Stack align={'left'} spacing={'sm'}>
					<Title order={4} align='left'>
						{data.title}
					</Title>
					<Text size='xs' align='left' mih={37.19}>
						{data.description}
					</Text>
					{!data.active ? (
						<Button radius={'md'} variant='outline'>
							Request Early Access
						</Button>
					) : null}
					{data.active && enabled ? (
						<Button
							radius={'md'}
							variant='outline'
							color='red'
							loading={buttonLoading}
						>
							{type === IntegrationType.Integration
								? 'Disconnect'
								: 'Uninstall'}
						</Button>
					) : null}
					{data.active && !enabled && !noButton ? (
						<Button
							radius={'md'}
							variant='outline'
							color={integrationFound ? 'red-fill' : 'green-fill'}
							onClick={() => handleIntegration(data.platform)}
							loading={buttonLoading}
						>
							{integrationFound
								? type === IntegrationType.Integration
									? 'Disconnect'
									: 'Uninstall'
								: type === IntegrationType.Integration
								? 'Connect'
								: 'Install'}
						</Button>
					) : null}
				</Stack>
			</Card>
		</>
	);
}
