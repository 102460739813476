import { DateTime } from 'luxon';
import { IMeeting } from '../interfaces/meeting';
import { BotStatus } from '../interfaces/recallai';
import { logger } from './logger';
import { getUpcomingMeetings } from '../api/api';

export const updateLiveMeetingsByBotEvent = (
	meetings: IMeeting[],
	meeting: IMeeting
) => {
	if (!meeting) {
		return meetings;
	}
	const status: any = meeting?.botMetadata?.status;

	if (
		[BotStatus.RecordingDone, BotStatus.Done, BotStatus.CallEnded].includes(
			status
		)
	) {
		return meetings.filter((m) => m.id !== meeting.id);
	}

	if (
		[BotStatus.InCallRecording, BotStatus.InCallNotRecording].includes(status)
	) {
		const existingMeeting = meetings.find((m) => m.id === meeting.id);

		if (existingMeeting) {
			return meetings.map((m) => {
				if (m.id === meeting.id) {
					return meeting;
				}
				return m;
			});
		}

		return [meeting, ...meetings];
	}

	if (
		[
			BotStatus.Ready,
			BotStatus.JoiningCall,
			BotStatus.InWaitingRoom,
			BotStatus.AnalysisDone,
			BotStatus.AnalysisFailed,
			BotStatus.MediaExpired,
		].includes(status)
	) {
		return meetings;
	}

	const errorMessage =
		'Unknown bot status received from recall ai' + ' ' + status;
	logger('error', errorMessage, meeting);
	return meetings;
};

export const getUserUpcomingMeetings = async (organizationID: string) => {
	try {
		const currentTime = DateTime.now().toISO();
		// Set the end time to the end of the current day
		const endOfToday = DateTime.now().endOf('day').toISO();
		const res = await getUpcomingMeetings(
			currentTime,
			endOfToday,
			organizationID
		);
		return res?.data?.data || [];
	} catch (error) {
		logger('error', 'Error fetching upcoming meetings', error);
		return [];
	}
};
