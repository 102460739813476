import { Paper, Switch } from '@mantine/core';
import { styles } from '../../../../../../settings/tabs/styles';
import { OrganizationSettings } from '../../../../../../../Atoms/settings';
import { useState } from 'react';
import { logger } from '../../../../../../../helpers/logger';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../../../../../helpers/notifications';
import { saveOrganizationSettings } from '../../../../../../../api/settings';
import { useParams } from 'react-router';
import {getEnvForGleap} from "../../../../../../../_utils/trackers";

interface Props {
	organizationSettings: OrganizationSettings;
	setOrganizationSettings: React.Dispatch<
		React.SetStateAction<OrganizationSettings>
	>;
}

export default function MeetingMinutes({
	organizationSettings,
	setOrganizationSettings,
}: Props) {
	const { classes } = styles();
	const { organizationID } = useParams();
	const { meeting_minutes: meetingMinutes } = organizationSettings;
	const [checked, setChecked] = useState<boolean>(meetingMinutes);
	if (checked !== meetingMinutes) {
		setChecked(meetingMinutes);
	}

	const handleMeetingMinutesChange = async (value: boolean) => {
		try {
			setChecked(value);
			const payload = [
				{
					key: 'meeting_minutes',
					value,
				},
			];
			const res = await saveOrganizationSettings(payload, organizationID);
			const details = res[0];
			setOrganizationSettings((prev) => ({
				...prev,
				[details?.key]: details?.value,
			}));
			showSuccessNotification({
				message: 'You have successfully changed the meeting minutes setting.',
			});
		} catch (error) {
			logger('error', 'error changing meeting_minutes', error);
			showFailureNotification({
				message:
					'Sorry, there was an error changing the meeting_minutes setting. Please try again.',
			});
		}
	};

	return (
		<Paper className={classes.card} shadow='xs' p='md' radius={'md'}>
			<Switch
				labelPosition='left'
				label='Meeting Minutes Docx Feature'
				description='Enabling this feature will have all users within this organization receive the meeting minutes email instead of the fast summary one.'
				size='sm'
				w={'100%'}
				width={'100%'}
				mb={'lg'}
				checked={checked}
				onChange={(e) => handleMeetingMinutesChange(e.currentTarget.checked)}
				styles={{
					body: {
						width: '100%',
						justifyContent: 'space-between',
						flex: 1,
					},
				}}
			/>
		</Paper>
	);
}
