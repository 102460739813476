import { DateTime } from 'luxon';

export const getYearFromDate = (dateString) => {
	// Parse the date string into a Luxon DateTime object
	const dateTime = DateTime.fromISO(dateString);
	// Get the year from the DateTime object
	const year = dateTime.year;
	return year;
};

export const getMonthAndDayFromDate = (dateString) => {
	// Parse the date string into a Luxon DateTime object
	const dateTime = DateTime.fromISO(dateString);
	// Format the date as "MMM. DD"
	const formattedDate = dateTime.toFormat('MMM. dd');
	return formattedDate;
};

export const getNormalDatefromISOString = (dateString) => {
	// Parse the date string into a Luxon DateTime object
	const dateTime = DateTime.fromISO(dateString);
	// Format the date as "MMM. DD"
	const formattedDate = dateTime.toFormat('MM-dd-yyyy');
	return formattedDate;
};

export const handleFormatTime = (date: Date, time: string) => {
	const luxonDate = DateTime.fromJSDate(date);

	// Parse the provided 24-hour time string
	const [hours, minutes] = time.split(':').map(Number);

	// Set the time part of the Luxon DateTime
	const combinedDateTime = luxonDate.set({
		hour: hours,
		minute: minutes,
		second: 0,
		millisecond: 0,
	});

	const combinedDateTimeISOString = combinedDateTime.toISO();
	return combinedDateTimeISOString;
};

export const combineDateTime = (date: Date, time: string) => {
	const luxonDate = DateTime.fromJSDate(date);

	// Parse the provided 24-hour time string
	const [hours, minutes] = time.split(':').map(Number);

	// Set the time part of the Luxon DateTime
	return luxonDate.set({
		hour: hours,
		minute: minutes,
		second: 0,
		millisecond: 0,
	});
};

export function convertDateFormat(dateStr) {
	try {
		const [month, day, year] = dateStr.split('-').map(Number);

		if (isNaN(month) || isNaN(day) || isNaN(year)) {
			throw new Error('Invalid date format');
		}

		const originalDate = new Date(year, month - 1, day); // Month is 0-based
		const formattedDate = `${originalDate.getFullYear()}-${(
			originalDate.getMonth() + 1
		)
			.toString()
			.padStart(2, '0')}-${originalDate.getDate().toString().padStart(2, '0')}`;

		return formattedDate;
	} catch (error) {
		return null; // Return null for invalid date formats
	}
}
