import { adminRoles, customerAdminRoles, roles } from '../components/constants';

// for support side
export const isAdmin = (roles: string[]) =>
	roles.some((role: string) => adminRoles.includes(role));

export const isSupportUser = (roles: string[]) =>
	roles.some((role: string) => role === 'support');

export const isUserAdmin = (roles: string[]) =>
	roles.some((role: string) => customerAdminRoles.includes(role));

export const isSuperadmin = (roles: string[]) =>
	roles.some((role: string) => role === 'superadmin');

export const isProjectManager = (roles: string[]) =>
	roles.some((role: string) => role === 'project-manager');

export const isMeetingOwnerOrProjectManager = (owningUserID, userID, roles) =>
	roles.includes('project-manager') || owningUserID === userID;
