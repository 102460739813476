import { useState } from 'react';
import {
	Button,
	Center,
	Divider,
	Group,
	Paper,
	Space,
	Stack,
	Stepper,
	Text,
	TextInput,
	Title,
	createStyles,
} from '@mantine/core';
import { faBuilding, faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReelayIcon } from '../../components/ReelayIcon';
import { getCurrentYear } from '../../helpers/data';
import { useForm } from '@mantine/form';
import { randomId } from '@mantine/hooks';
import ReactPlayer from 'react-player';
import { signUp } from '../../api/api';
import TermsOfUseCheckbox from '../../components/Checkboxes/TermsOfUseCheckbox';
import { privacyLink, termsLink } from '../../components/constants';

const useStyles = createStyles((theme) => ({
	page: {
		background:
			'linear-gradient(180deg, rgba(79, 0, 245, 0.12) 0%, rgba(0, 128, 245, 0.12) 100%)',
		height: '100vh',
		verticalAlign: 'center',
	},
	label: {
		textAlign: 'center',
		width: '352px',
	},
	controls: {
		[theme.fn.smallerThan('xs')]: {
			flexDirection: 'column-reverse',
		},
		width: '352px',
	},
	control: {
		[theme.fn.smallerThan('xs')]: {
			width: '100%',
			textAlign: 'center',
		},
		width: '352px',
		height: '42px',
		fontSize: '16px',
	},
	copy: {
		fontSize: '12px',
		lineHeight: '22px',
		color: theme.colors.primary[5],
	},
	welcome: {
		fontSize: '32px',
		fontWeight: 'normal',
		lineHeight: '28px',
		marginTop: '40px',
	},
	stepper: {
		width: '100%',
	},
	paper: {
		width: '800px',
	},
}));

export const SignupPage = () => {
	const { classes } = useStyles();
	const [active, setActive] = useState(0);
	const [loading, setLoading] = useState(false);

	const form = useForm({
		initialValues: {
			orgName: '',
			firstName: '',
			lastName: '',
			email: '',
			phone: '',
			invitees: [{ email: '', key: randomId() }],
			agreed_with_terms: false,
		},
		validate: (values) => {
			if (active === 0) {
				return {
					orgName:
						values.orgName.trim().length < 2
							? 'Organization Name must include at least 2 characters'
							: null,
				};
			}

			if (active === 1) {
				return {
					firstName:
						values.firstName.trim().length < 2
							? 'Name must include at least 2 characters'
							: null,
					lastName:
						values.firstName.trim().length < 2
							? 'Name must include at least 2 characters'
							: null,
					email: /^\S+@\S+$/.test(values.email) ? null : 'Invalid email',
					agreed_with_terms: values.agreed_with_terms
						? null
						: 'Field is required.',
				};
			}

			return {};
		},
	});

	const nextStep = async () => {
		if (active === 0) {
			setActive((current) => {
				if (form.validate().hasErrors) {
					return current;
				}
				return current < 2 ? current + 1 : current;
			});
		}

		if (active === 1) {
			setLoading(true);
			const { orgName, firstName, lastName, email, phone } = form.values;
			await signUp(orgName, firstName, lastName, email, phone);
			await setLoading(false);
			setActive((current) => {
				if (form.validate().hasErrors) {
					return current;
				}
				return current < 2 ? current + 1 : current;
			});
		}
	};

	const prevStep = () =>
		setActive((current) => (current > 0 ? current - 1 : current));

	return (
		<Center className={classes.page}>
			<Stack>
				<Space h='xl' />
				<Space h='xl' />
				<Stack align={'center'}>
					<ReelayIcon />
					<Space h='xl' />
					<Title align={'center'} order={1} weight={400} color={'primary-text'}>
						Sign up for Reelay
					</Title>
					<Space h='xl' />
					<Divider my='sm' color={'reelay-secondary'} />
					<Center>
						<Paper className={classes.paper} radius={'md'} shadow='xl' p={'lg'}>
							<form onSubmit={form.onSubmit(nextStep)}>
								<Stepper
									styles={{
										content: {
											flexGrow: 1,
											padding: '10px 25px',
											borderLeft: '1px solid #E0E0E0',
										},
										root: {
											display: 'flex',
										},
										step: {
											marginRight: '25px',
										},
										steps: {
											justifyContent: 'center',
										},
									}}
									className={classes.stepper}
									active={active}
									breakpoint='sm'
									orientation='vertical'
								>
									<Stepper.Step
										label='Step One'
										description='Create an Organization'
										icon={<FontAwesomeIcon icon={faBuilding} />}
									>
										<Title order={4} weight={400}>
											Company Info
										</Title>
										<Space h='xl' />
										<Space h='xl' />
										<TextInput
											placeholder='Company Name'
											{...form.getInputProps('orgName')}
										/>
									</Stepper.Step>

									<Stepper.Step
										label='Step Two'
										description='Personal Info'
										icon={<FontAwesomeIcon icon={faUser} />}
									>
										<Stack>
											<Title order={4} weight={400}>
												Personal Info
											</Title>
											<Group grow>
												<TextInput
													placeholder='First Name'
													{...form.getInputProps('firstName')}
												/>
												<TextInput
													placeholder='Last Name'
													{...form.getInputProps('lastName')}
												/>
											</Group>
											<TextInput
												mt='md'
												placeholder='Email'
												{...form.getInputProps('email')}
											/>
											<TextInput
												mt='md'
												placeholder='Phone'
												{...form.getInputProps('phone')}
											/>
											<TermsOfUseCheckbox form={form} />
										</Stack>
										<Space h='xl' />
									</Stepper.Step>
									<Stepper.Completed>
										<Title order={4} weight={400}>
											Congratulations{' '}
										</Title>
										<Space h='md' />
										<Text size={14} weight={400}>
											Now that you’ve signed up, check your email for account
											verification. You’re ready to start using Reelay, but if
											you have any questions you can always reach us at
											support@reelay.com
										</Text>
										<Space h='md' />
										<Center>
											<ReactPlayer
												url='https://www.youtube.com/watch?v=I5I7rHfjCG4'
												width={'100%'}
												config={{
													youtube: {
														playerVars: {
															showinfo: 0,
															modestbranding: 1,
															controls: 0,
														},
													},
												}}
											/>
										</Center>
										<Space h='md' />
										<Space h='xl' />
									</Stepper.Completed>
								</Stepper>
							</form>
						</Paper>
					</Center>
					<Center>
						<Group mt='sm'>
							{active !== 0 ||
								(active > 1 && (
									<Button variant='default' onClick={prevStep}>
										Back
									</Button>
								))}
							{active !== 2 && (
								<Button onClick={nextStep} loading={loading}>
									Next step
								</Button>
							)}
						</Group>
					</Center>
				</Stack>
				<Space h={'sm'} />
				<Stack className={classes.copy} align={'center'}>
					<Text>v{process.env.REACT_APP_VERSION}</Text>
					<Text>Copyright © {getCurrentYear()} Reelay Meetings, Inc.</Text>
					<Text>
						<a
							style={{
								textDecoration: 'none',
								color: 'black',
								fontWeight: 'bold',
							}}
							rel={'noopener noreferrer'}
							target={'_blank'}
							href={termsLink}
						>
							Terms of Use
						</a>{' '}
						|{' '}
						<a
							style={{
								textDecoration: 'none',
								color: 'black',
								fontWeight: 'bold',
							}}
							rel={'noopener noreferrer'}
							target={'_blank'}
							href={privacyLink}
						>
							{' '}
							Privacy Policy
						</a>
					</Text>
				</Stack>
			</Stack>
		</Center>
	);
};
